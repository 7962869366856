import {WorkCategoryDTO} from './work-category';
import {WorkRequestDTO} from './work-request';
import {CommonDTO} from './common';

export class BaseWorkSubCategoryDTO extends CommonDTO {

	id:number;
	englishTitle:string;
	arabicTitle:string;
	workCategoryDTO:WorkCategoryDTO = new WorkCategoryDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	workRequestList:Array<WorkRequestDTO>;
}