import {SupplierDTO} from './supplier';
import {StatusDTO} from './status';
import {WorkRequestDTO} from './work-request';
import {CommonDTO} from './common';

export class BaseWorkRequestBidDTO extends CommonDTO {

	id:number;
	sequanceNo:number;
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	supplierDTO:SupplierDTO = new SupplierDTO();
	statusDTO:StatusDTO = new StatusDTO();
	checkCost:number;
	bidTime:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}