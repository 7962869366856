import { Component, ViewChild, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { WorkRequestHistoryDTO } from '../../models/work-request-history';
import { PurchaseOrderDTO } from '../../models/purchase-order';
import { StatusDTO } from '../../models/status';
import { WorkRequestService } from '../../services/work-request.service';
import { HuloolConstants } from '../../hulool.constants';
import { WorkRequestDTO } from '../../models/work-request';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonComponent } from '../common.component';
declare var $:JQueryStatic;

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css']
})
export class PurchaseOrderComponent extends CommonComponent implements OnInit {

	loading:boolean = false;
	mode:string = "list";
	workRequestHistoryList:WorkRequestHistoryDTO[] = [];
	purchaseOrderList:PurchaseOrderDTO[] = [];
	purchaseOrderDTO:PurchaseOrderDTO = new PurchaseOrderDTO();
	purchaseOrderSearchDTO:PurchaseOrderDTO = new PurchaseOrderDTO();
	statusList:StatusDTO[] = [];

	@ViewChild("startDate") startDate:ElementRef;
	@ViewChild("endDate") endDate:ElementRef;
	
	quatationApprovedStatus = HuloolConstants.QUATATION_APPROVED;

	@ViewChild("cancelPurchaseOrderModal") cancelPurchaseOrderModal:TemplateRef<any>;

	modalRef: BsModalRef;
	
	canceledStatusList:number[] = [
			HuloolConstants.QUATATION_APPROVED,
			HuloolConstants.PURCHASE_ORDER_COMPLETED
		];
		
	constructor(public workRequestService:WorkRequestService, private modalService: BsModalService) {
		super();
	}
	
	ngOnInit(): void {

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_APPROVED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.PURCHASE_ORDER_COMPLETED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.PURCHASE_ORDER_CANCELED_BY_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		let date:Date = new Date();

		date.setDate(date.getDate() - 7);

		let day:string = String(date.getDate());
		let month:string = String(date.getMonth() + 1);
		let year:string = String(date.getFullYear());

		this.startDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		date = new Date();
		day = String(date.getDate());
		month = String(date.getMonth() + 1);
		year = String(date.getFullYear());

		this.endDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		this.searchPurchaseOrders();

		$(".datePicker").each(function(){

            $(this).datepicker({format: "dd-mm-yyyy", uiLibrary: "bootstrap4"});
        })
	}

	searchPurchaseOrders(){

		this.purchaseOrderSearchDTO.startDate = this.startDate.nativeElement.value;
		this.purchaseOrderSearchDTO.endDate = this.endDate.nativeElement.value;

		this.loading = true;

		this.workRequestService.searchPurchaseOrders(this.purchaseOrderSearchDTO).subscribe((purchaseOrderList:PurchaseOrderDTO[]) => {

			this.loading = false;

			this.purchaseOrderList = purchaseOrderList;
		});
	}

	exportPurchaseOrders(){

		this.workRequestService.exportPurchaseOrders(this.purchaseOrderSearchDTO);
	}

	viewWorkRequestDetails(purchaseOrderDTO:PurchaseOrderDTO){

		this.mode = "view";

		this.purchaseOrderDTO = purchaseOrderDTO;
	}

	viewWorkRequestHistory(workRequestDTO:WorkRequestDTO){

		this.mode = "history";

		this.workRequestService.fetchWorkRequestHistory(workRequestDTO).subscribe((workRequestHistoryList:WorkRequestHistoryDTO[]) => {

			this.workRequestHistoryList = workRequestHistoryList;
		});
	}

	openCancelPurchaseOrderModal() {
		
		this.modalRef = this.modalService.show(this.cancelPurchaseOrderModal);
	}

	cancelPurchaseOrderByHulool(){

		this.modalRef.hide();
		
		this.workRequestService.cancelPurchaseOrderByHulool(this.purchaseOrderDTO).subscribe((purchaseOrderDTO:PurchaseOrderDTO) => {

			this.purchaseOrderDTO = purchaseOrderDTO;
		});
	}

	formatMinutes(time:number){
				
		let formattedTime:string = "";
		
		let hours:string = String(Math.floor(time/60));
		
		let minutes:string = String(time%60);
		
		let formattedHours:string = "00".substr(hours.length) + hours;
		
		let formattedMinutes:string = "00".substr(minutes.length) + minutes;
		
		formattedTime = formattedHours + ":" + formattedMinutes;
		
		return formattedTime;
	}
}
