import {UserDTO} from './user';
import {PaymentItemDTO} from './payment-item';
import {AddressDTO} from './address';
import {CreditHistoryDTO} from './credit-history';
import {PurchaseOrderDTO} from './purchase-order';
import {WorkQuatationDTO} from './work-quatation';
import {OnlinePaymentDTO} from './online-payment';
import {WorkRequestDTO} from './work-request';
import {WorkOrderDTO} from './work-order';

export class BaseCustomerDTO extends UserDTO {

	name:string;
	creditAmount:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	paymentItemList:Array<PaymentItemDTO>;
	addressList:Array<AddressDTO>;
	creditHistoryList:Array<CreditHistoryDTO>;
	purchaseOrderList:Array<PurchaseOrderDTO>;
	workQuatationList:Array<WorkQuatationDTO>;
	onlinePaymentList:Array<OnlinePaymentDTO>;
	workRequestList:Array<WorkRequestDTO>;
	workOrderList:Array<WorkOrderDTO>;
}