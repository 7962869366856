import { Component, OnInit } from '@angular/core';
import { JobSchedulerDTO } from '../../models/job-scheduler';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import {ParameterService} from '../../services/parameter.service';
import { JobSchedulerHistoryDTO } from '../../models/job-scheduler-history';

@Component({
  selector: 'app-job-schedule',
  templateUrl: './job-schedule.component.html',
  styleUrls: ['./job-schedule.component.scss']
})
export class JobScheduleComponent implements OnInit {

	mode:string = "";
  	loading:boolean = false;
	jobSchedulerList:JobSchedulerDTO[] = [];
  	jobSchedulerDTO:JobSchedulerDTO = new JobSchedulerDTO();
  
	constructor(private parameterService:ParameterService, 
				private router: Router,
				private modalService: BsModalService) {
	}

	ngOnInit(): void {

		this.getJobSchedulerList();
	}

	getJobSchedulerList(){

		this.loading = true;

		this.mode = "list";
		
		this.parameterService.getJobSchedulerList().subscribe((jobSchedulerList:JobSchedulerDTO[])=>{

			this.loading = false;

			this.jobSchedulerList = jobSchedulerList;
		})
	}

	viewJobScheduler(jobSchedulerDTO:JobSchedulerDTO){

		this.mode = "edit";
		
		this.jobSchedulerDTO = jobSchedulerDTO;

		this.parameterService.getJobSchedulerHistoryList(this.jobSchedulerDTO).subscribe((jobSchedulerHistoryList:JobSchedulerHistoryDTO[])=>{

			this.jobSchedulerDTO.jobSchedulerHistoryList = jobSchedulerHistoryList;
		})
	}

	runJobScheduler(url:string){

		this.parameterService.runJobScheduler(url).subscribe(()=>{

			this.getJobSchedulerList();
		})
	}

	clearJobSchedulerLogs(){

		this.parameterService.clearJobSchedulerLogs(this.jobSchedulerDTO).subscribe(()=>{

			this.jobSchedulerDTO.jobSchedulerHistoryList = new Array<JobSchedulerHistoryDTO>();
		})
	}
}
