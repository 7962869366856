import { Component, TemplateRef, ViewChild } from '@angular/core';
// import { BaseSupplierSpecializationPriceService } from '../../base-service/base-supplier-specialization-price.service';
import { SupplierSpecializationPriceDTO } from '../../models/supplier-specialization-price';
import { CommonComponent } from '../../components/common.component';
// import { BaseSupplierSpecializationService } from '.././base-service/base-supplier-specialization.service';
import { SupplierSpecializationDTO } from '../../models/supplier-specialization';
import { SupplierService } from '../../services/supplier.service';
import { WorkCategoryService } from '../../services/work-category.service';
import { WorkCategoryDTO } from '../../models/work-category';
import { StatusDTO } from '../../models/status';
import { HuloolConstants } from '../../hulool.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SupplierDTO } from '../../models/supplier';



@Component({
	templateUrl: './supplier-specialization-price.component.html'
})
export class SupplierSpecializationPriceComponent extends CommonComponent {

	loading:boolean = false;
	message:string = null;

	mode:string = "list";

	supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO = new SupplierSpecializationPriceDTO();
	supplierSpecializationPriceSearchDTO:SupplierSpecializationPriceDTO = new SupplierSpecializationPriceDTO();
	supplierSpecializationPriceList:Array<SupplierSpecializationPriceDTO> = new Array<SupplierSpecializationPriceDTO>();
	supplierSpecializationList:Array<SupplierSpecializationPriceDTO> = new Array<SupplierSpecializationPriceDTO>();

	workCategoryList:WorkCategoryDTO[] = [];

	statusList:StatusDTO[] = [];
	submittedSpeclizationPrice:number = HuloolConstants.SPECIALIZATION_PRICE_SUBMITTED;
	approvedSpeclizationPrice:number = HuloolConstants.SPECIALIZATION_PRICE_APPROVED;
	rejectedSpeclizationPrice:number = HuloolConstants.SPECIALIZATION_PRICE_REJECTED;
	rejectedBySupplierSpeclizationPrice:number = HuloolConstants.SPECIALIZATION_PRICE_REJECTED_BY_SUPPLIER;
	deletedByHuloolSpeclizationPrice:number = HuloolConstants.SPECIALIZATION_PRICE_REJECTED_BY_HULOOL;
	selectedWorkCategorySpecilzationPrice:SupplierSpecializationPriceDTO ;

	supplierList:SupplierDTO[] = [];

	@ViewChild("approveSpeclizationPriceModal") approveSpeclizationPriceModal:TemplateRef<any>;
	@ViewChild("rejectSpeclizationPriceModal") rejectSpeclizationPriceModal:TemplateRef<any>;
	@ViewChild("deleteSpeclizationPriceModal") deleteSpeclizationPriceModal:TemplateRef<any>;
	@ViewChild("addSpeclizationPriceModal") addSpeclizationPriceModal:TemplateRef<any>;

	modalRef: BsModalRef;
	public constructor(public supplierService:SupplierService , public workCategoryService:WorkCategoryService,private modalService: BsModalService) {
		super();
	}

	ngOnInit() {

		let workCategoryDTO:WorkCategoryDTO = new WorkCategoryDTO();
		workCategoryDTO.active = true;

		this.supplierService.getApprovedSuppliers(new SupplierDTO()).subscribe((supplierList:SupplierDTO[]) => {

			this.supplierList = supplierList;

		});

		this.workCategoryService.searchWorkCategoryList(workCategoryDTO).subscribe((workCategoryList:WorkCategoryDTO[]) => {

			this.workCategoryList = workCategoryList;
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_PRICE_APPROVED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_PRICE_REJECTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_PRICE_SUBMITTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_PRICE_REJECTED_BY_SUPPLIER).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_PRICE_REJECTED_BY_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.searchSupplierSpecializationPriceList();

		//this.getSupplierSpecializationList();
	}

	public toViewSupplierSpecializationPrice(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO) {

		let getSupplierSpecializationPriceDTO:SupplierSpecializationPriceDTO = new SupplierSpecializationPriceDTO();
		getSupplierSpecializationPriceDTO.id = supplierSpecializationPriceDTO.id ; 
		this.mode = "view";

		this.supplierService.getSupplierSpecializationPriceById(getSupplierSpecializationPriceDTO).subscribe((fullSupplierSpecializationPriceDTO:SupplierSpecializationPriceDTO) => {
	
			this.supplierSpecializationPriceDTO = fullSupplierSpecializationPriceDTO;
		});
	
	}

	public toAddSupplierSpecializationPrice() {

		this.mode = "edit";

		this.supplierSpecializationPriceDTO = new SupplierSpecializationPriceDTO();
	}

	public toEditSupplierSpecializationPrice(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO) {

		this.mode = "edit";

		this.supplierSpecializationPriceDTO = supplierSpecializationPriceDTO;
	}

	public addSpecializationPrice() {

		let result:string = this.isValidForm();

		if(result == null) {
			this.modalRef.hide();
			this.supplierSpecializationPriceDTO.source = "admin";

			this.supplierService.saveSpecializationPriceDetails(this.supplierSpecializationPriceDTO).subscribe(() => {
	
				this.message = "Specialization Price has been added successfully";
	
				setTimeout(()=>{
	
					this.message = null;
	
				}, 3000);
	
				this.searchSupplierSpecializationPriceList();
			});

		} else {

			this.error(result);
		}
	}

	public searchSupplierSpecializationPriceList() {
		this.mode = "list";
		this.loading = true;

		this.supplierService.searchSupplierSpecializationPriceList(this.supplierSpecializationPriceSearchDTO).subscribe((supplierSpecializationPriceList:SupplierSpecializationPriceDTO[]) => {
		
			this.loading = false;
			this.supplierSpecializationPriceList = supplierSpecializationPriceList;
		});
	}

	saveWorkCategory(){
		this.supplierSpecializationPriceDTO.id = this.selectedWorkCategorySpecilzationPrice.id;
		this.supplierSpecializationPriceDTO.specializationDTO.id = this.selectedWorkCategorySpecilzationPrice.specializationDTO.id;
		this.supplierSpecializationPriceDTO.specializationDTO.workCategoryDTO.id= this.selectedWorkCategorySpecilzationPrice.specializationDTO.workCategoryDTO.id;
	}

	public getSupplierSpecializationList(supplierDTO:SupplierDTO) {
		if(supplierDTO.id!=null && supplierDTO.id!=0){

			this.supplierSpecializationPriceDTO.supplierId = supplierDTO.id;

			this.loading = true;
			this.supplierService.fetchSupplierSpecializationPriceList(supplierDTO).subscribe((supplierSpecializationList:SupplierSpecializationPriceDTO[]) => {
				this.loading = false;
				this.supplierSpecializationList = supplierSpecializationList;
			});
		}
	}

	openApproveSpeclizationPriceModal(){

		this.modalRef = this.modalService.show(this.approveSpeclizationPriceModal);
	}

	openAddSpeclizationPriceModal(){

		this.modalRef = this.modalService.show(this.addSpeclizationPriceModal);
	}

	openRejectSpeclizationPriceModal(){

		this.modalRef = this.modalService.show(this.rejectSpeclizationPriceModal);
	}

	openDeleteSpeclizationPriceModal(){

		this.modalRef = this.modalService.show(this.deleteSpeclizationPriceModal);
	}

	approveSpecializationPrice(){
		
		this.modalRef.hide();

		this.supplierService.approveSpecializationPrice(this.supplierSpecializationPriceDTO).subscribe(() => {

			this.message = "Specialization Price " + this.supplierSpecializationPriceDTO.id + " has been approved successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSupplierSpecializationPriceList();
		});
	}

	rejectSpecializationPrice(){
		
		// this.rejectionReasonList.splice(this.rejectionReasonList.indexOf(this.specializationDTO.id), 1);

		this.modalRef.hide();

		this.supplierService.rejectSpecializationPrice(this.supplierSpecializationPriceDTO).subscribe(() => {

			this.message = "Specialization Price " + this.supplierSpecializationPriceDTO.id + " has been rejected successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSupplierSpecializationPriceList();
		});
	}

	deleteSpecializationPrice(){
		
		this.modalRef.hide();

		this.supplierService.deleteSpecializationPriceByHulool(this.supplierSpecializationPriceDTO).subscribe(() => {

			this.message = "Specialization Price " + this.supplierSpecializationPriceDTO.id + " has been deleted successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSupplierSpecializationPriceList();
		});
	}
	
	
	updatePriceDocument(event:any){

		if(event.target.files && event.target.files[0]) {

			var reader = new FileReader();

			reader.onload = (event:any) => {

				this.supplierSpecializationPriceDTO.priceDocument = event.target.result;
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}

	downloadPriceDocumentPDF(){
		let supplierSpecializationPriceDTO = new SupplierSpecializationPriceDTO();
		supplierSpecializationPriceDTO.id = this.supplierSpecializationPriceDTO.id
		this.supplierService.exportPriceDocument(this.supplierSpecializationPriceDTO);

	}
}