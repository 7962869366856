import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import {AdvertiserDTO} from '../../models/advertiser';
import {SupplierDTO} from '../../models/supplier';
import {OfferService} from '../../services/offer.service';
import {SupplierService} from '../../services/supplier.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-advertisor',
  templateUrl: './advertisor.component.html',
  styleUrls: ['./advertisor.component.css']
})
export class AdvertisorComponent implements OnInit {

	message:string = null;
	loading:boolean = false;
	supplierList:SupplierDTO[] = [];
	defaultSupplierDTO:SupplierDTO = new SupplierDTO();
	advertiserList:AdvertiserDTO[] = [];
	advertiserDTO:AdvertiserDTO = new AdvertiserDTO();
	advertiserSearchDTO:AdvertiserDTO = new AdvertiserDTO();
	mode:string = "list";
	
	@ViewChild("saveAdvertiserModal") saveAdvertiserModal:TemplateRef<any>;
	
	modalRef: BsModalRef;

	constructor(private offerService:OfferService,
				private supplierService:SupplierService,
				private modalService: BsModalService) {
	}

	ngOnInit(): void {

		this.searchAdvertisers();

		this.supplierService.getApprovedSuppliers(new SupplierDTO()).subscribe((supplierList:SupplierDTO[]) => {

			this.supplierList = supplierList;
		});
	}

	searchAdvertisers(){

		this.loading = true;

		this.mode = "list";
		
		this.offerService.searchAdvertisers(this.advertiserSearchDTO).subscribe((advertiserList:AdvertiserDTO[]) => {

			this.loading = false;

			this.advertiserList = advertiserList;
		});
	}

	exportAdvertisers(){

		this.offerService.exportAdvertisers(this.advertiserSearchDTO);
	}

	toAddAdvertiser(){

		this.mode = "edit";
		
		this.advertiserDTO = new AdvertiserDTO();
		this.advertiserDTO.supplierDTO = this.defaultSupplierDTO;
	}

	editAdvertiser(advertiserDTO:AdvertiserDTO){

		this.mode = "edit";

		this.advertiserDTO = advertiserDTO;
	}

	selectSupplier(supplierDTO:SupplierDTO){

		this.advertiserDTO.englishName = supplierDTO != null ? supplierDTO.englishName : null;
		this.advertiserDTO.arabicName = supplierDTO != null ? supplierDTO.arabicName : null;
		this.advertiserDTO.logo = supplierDTO != null ? supplierDTO.logo : null;
	}

	updateImage(event:any){

		if(event.target.files && event.target.files[0]) {

			var reader = new FileReader();

			reader.onload = (event:any) => {

				this.advertiserDTO.logo = event.target.result;
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}

	openSaveAdvertiserModal() {
		
		this.modalRef = this.modalService.show(this.saveAdvertiserModal);
	}

	saveAdvertiser(){

		this.modalRef.hide();

		this.offerService.saveAdvertiser(this.advertiserDTO).subscribe((advertiserDTO:AdvertiserDTO) => {
			
			this.message = "Advertiser " + advertiserDTO.id + " has been updated successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);
			
			this.searchAdvertisers();
		});
	}
}
