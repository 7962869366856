import {CustomerDTO} from './customer';
import {CityDTO} from './city';
import {WorkRequestDTO} from './work-request';
import {CommonDTO} from './common';

export class BaseAddressDTO extends CommonDTO {

	id:number;
	customerDTO:CustomerDTO = new CustomerDTO();
	title:string;
	flat:string;
	building:string;
	road:string;
	block:number;
	cityDTO:CityDTO = new CityDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	workRequestList:Array<WorkRequestDTO>;
}