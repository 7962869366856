import { Component, OnInit } from '@angular/core';
import { OnlinePaymentDTO } from '../../models/online-payment';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {

	loading:boolean = false;
	onlinePaymentList:Array<OnlinePaymentDTO> = new Array<OnlinePaymentDTO>();
	onlinePaymentSearchDTO:OnlinePaymentDTO = new OnlinePaymentDTO();

	constructor(public customerService:CustomerService) {
	}

	ngOnInit() {

		this.searchOnlinePayments();
	}

	public searchOnlinePayments() {

		this.loading = true;

		this.customerService.searchOnlinePayments(this.onlinePaymentSearchDTO).subscribe((onlinePaymentList:Array<OnlinePaymentDTO>)=> {

			this.loading = false;

			this.onlinePaymentList = onlinePaymentList;
		});
	}

	exportOnlinePayments(){

		this.customerService.exportOnlinePayments(this.onlinePaymentSearchDTO);
	}
}
