import {UserDTO} from './user';
import {CommonDTO} from './common';

export class BaseRoleDTO extends CommonDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	userList:Array<UserDTO>;
}