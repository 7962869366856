import { Component, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {UserDTO} from './models/user';
import {UserService} from './services/user.service';
import { DataService } from './services/data.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from './services/shared-service';
import { CustomerDTO } from './models/customer';
import { CustomerService } from './services/customer.service';
import { SupplierDTO } from './models/supplier';
import { SupplierService } from './services/supplier.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
	userDTO:UserDTO = new UserDTO();
	loggedIn:boolean = false;
	modalRef: BsModalRef;
	error:string;
	customerDTO:CustomerDTO;
	supplierDTO:SupplierDTO;
	imageFile:string ;
	//Pop Up changes
	message:string = null;
	//error:string = null;
	confirmTitle:string = null;
	confirmContent:string = null;
	functionName:Function = null;

	@ViewChild("errorHandleModal") errorHandleModal:TemplateRef<any>;
	@ViewChild("customerDetailsModal") customerDetailsModal:TemplateRef<any>;
	@ViewChild("supplierDetailsModal") supplierDetailsModal:TemplateRef<any>;
	@ViewChild("pdfViewerModal") pdfViewerModal:TemplateRef<any>;

	constructor(private translate: TranslateService, 
				public userService:UserService,
				public data:DataService,
				public customerService:CustomerService,
				public supplierService:SupplierService,
				public modalService: BsModalService) {

		this.translate.setDefaultLang("en");

		this.userDTO.email = "";
		this.userDTO.password = "";

		this.data.subscribe((error:string)=>{

			if(error != null){

				this.error = error;

				this.modalRef = this.modalService.show(this.errorHandleModal);
			}
		});

		SharedService.subsribeDisplayCustomerDetails((customerID:string)=>{
		
			if(customerID!=null && customerID!=""){
				this.customerDTO = new CustomerDTO();
				this.customerDTO.id =+customerID;

				this.customerService.getCustomerAccount(this.customerDTO).subscribe((customerDTO:CustomerDTO) => {

					this.customerDTO = customerDTO;
					this.modalRef = this.modalService.show(this.customerDetailsModal, {class: 'modal-lg'});
				});
			}	
			
		
		});

		SharedService.subsribeDisplaySupplierDetails((supplierId:string)=>{
		
			if(supplierId!=null && supplierId!=""){
				this.supplierDTO = new SupplierDTO();
				this.supplierDTO.id =+supplierId;

				this.supplierService.getSupplierAccount(this.supplierDTO).subscribe((supplierDTO:SupplierDTO) => {

					this.supplierDTO = supplierDTO;
					this.modalRef = this.modalService.show(this.supplierDetailsModal, {class: 'modal-lg'});
				});
			}	
			
		
		});


		SharedService.subsribeDisplayPdfViewerDetails((imageFile:string)=>{
		
			if(imageFile!=null && imageFile!=""){
				this.imageFile = imageFile;
				this.modalRef = this.modalService.show(this.pdfViewerModal, {class: 'modal-lg'});
			}		
		
		});


		SharedService.subscribeConfirm((confirmTitle:string, confirmContent:string, functionName:Function)=>{

			this.confirmTitle = confirmTitle;
			this.confirmContent = confirmContent;
			this.functionName = functionName;

			$("#confirmModal").modal("show");
		});

		SharedService.subscribeSuccess((message:string)=>{

			this.message = message;
		});

		SharedService.subscribeError((error:string)=>{

			console.log(error);
			
			this.error = error;
		});
	}

	login(){

		this.userService.login(this.userDTO).subscribe((userDTO:UserDTO) => {

			this.loggedIn = true;
		});
	}

	logout(){

		this.loggedIn = false;
	}

	confirmAction(){

		this.functionName.call(null);
	}
}
