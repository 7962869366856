import {CustomerDTO} from './customer';
import {WorkRequestDTO} from './work-request';
import {SupplierDTO} from './supplier';
import {WorkOrderDTO} from './work-order';
import {OnlinePaymentDTO} from './online-payment';
import {WorkQuatationDTO} from './work-quatation';
import {CommonDTO} from './common';

export class BasePurchaseOrderDTO extends CommonDTO {

	id:number;
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	workOrderDTO:WorkOrderDTO = new WorkOrderDTO();
	workQuatationDTO:WorkQuatationDTO = new WorkQuatationDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	supplierDTO:SupplierDTO = new SupplierDTO();
	orderTime:string;
	amount:number;
	paymentDTO:OnlinePaymentDTO = new OnlinePaymentDTO();
	title:string;
	summary:string;
	completeTitle:string;
	completeSummary:string;
	completeTime:string;
	manualAdjustment:number;
	attachment:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}