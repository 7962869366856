import {AdvertiserDTO} from './advertiser';
import {OfferViewDTO} from './offer-view';
import {CommonDTO} from './common';

export class BaseOfferDTO extends CommonDTO {

	id:number;
	englishTitle:string;
	arabicTitle:string;
	englishDescription:string;
	arabicDescription:string;
	startDate:string;
	endDate:string;
	target:string;
	area:string;
	advertiserDTO:AdvertiserDTO = new AdvertiserDTO();
	photo:string;
	detailsPhoto:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	offerViewList:Array<OfferViewDTO>;
}