import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import {SupplierService} from '../../services/supplier.service';
import {PaymentDTO} from '../../models/payment';
import {HuloolConstants} from '../../hulool.constants';
import {StatusDTO} from '../../models/status';
import {WorkRequestService} from '../../services/work-request.service';
import { SupplierDTO } from '../../models/supplier';
import { PaymentItemDTO } from '../../models/payment-item';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonComponent } from '../common.component';

@Component({
  selector: 'app-monthly-reconciliation',
  templateUrl: './monthly-reconciliation.component.html',
  styleUrls: ['./monthly-reconciliation.component.css']
})
export class MonthlyReconciliationComponent extends CommonComponent implements OnInit {

	loading:boolean = false;
	message:string = null;
	supplierList:SupplierDTO[] = [];
	paymentDTO:PaymentDTO = new PaymentDTO();
	paymentItemList:PaymentItemDTO[] = [];
	paymentItemMap:Map<number, PaymentItemDTO> = new Map<number, PaymentItemDTO>();
	paymentItemDTO:PaymentItemDTO = new PaymentItemDTO();
	paymentItemSearchDTO:PaymentItemDTO = new PaymentItemDTO();
	selectedPaymentList:any = {};

	paymentToHulool:number = HuloolConstants.PAYMENT_TO_HULOOL;

	statusList:StatusDTO[] = [];

	@ViewChild("processPaymentModal") processPaymentModal:TemplateRef<any>;

	modalRef: BsModalRef;
	
	constructor(private supplierService:SupplierService, 
				private workRequestService:WorkRequestService,
				private modalService: BsModalService) {
					super();
	}

	ngOnInit(): void {

		this.supplierService.getApprovedSuppliers(new SupplierDTO()).subscribe((supplierList:SupplierDTO[]) => {

			this.supplierList = supplierList;

			this.paymentItemSearchDTO.supplierDTO = this.supplierList.length != 0 ? this.supplierList[0] : null;

			this.searchPaymentItems();
		});
			
		this.workRequestService.getStatusById(HuloolConstants.PAYMENT_TO_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.REFUNDED_TO_CUSTOMER).subscribe((statusDTO:StatusDTO) => {
			
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.PAYMENT_TO_SUPPLIER).subscribe((statusDTO:StatusDTO) => {
			
			this.statusList.push(statusDTO);
		});
	}

	searchPaymentItems(){

		this.selectedPaymentList = {};

		this.paymentDTO.amount = 0;
		
		this.loading = true;

		this.supplierService.searchPaymentItems(this.paymentItemSearchDTO).subscribe((paymentItemList:PaymentItemDTO[]) => {

			this.loading = false;

			this.paymentItemList = paymentItemList;

			this.paymentItemMap = new Map<number, PaymentItemDTO>();

			for(let i=0; i<this.paymentItemList.length; i++){

				let paymentItemDTO:PaymentItemDTO = this.paymentItemList[i];

				this.paymentItemMap.set(paymentItemDTO.id, paymentItemDTO);
			}

			this.paymentItemDTO = new PaymentItemDTO();
			this.paymentItemDTO.transaction = "Hulool Funds";
			this.paymentItemDTO.supplierDTO.id = this.paymentItemSearchDTO.supplierDTO.id;
			this.paymentItemMap.set(0, this.paymentItemDTO);
		});
	}

	exportPaymentItems(){

		this.supplierService.exportPaymentItems(this.paymentItemSearchDTO);
	}

	updateTotalAmount(){

		this.paymentDTO.amount = 0;

		for(let paymentId in this.selectedPaymentList){

			if(this.selectedPaymentList[paymentId] == true){

				let amount:number = this.paymentItemMap.get(Number(paymentId)).finalAmountToSupplier;

				if(amount != null && amount != 0){

					this.paymentDTO.amount+= Number(amount);
				}
			}
		}

		this.paymentItemDTO.amount = this.paymentItemDTO.finalAmountToSupplier;
		this.paymentItemDTO.amountToSupplier = this.paymentItemDTO.finalAmountToSupplier;
	}

	openProcessPaymentModal() {
		
		this.modalRef = this.modalService.show(this.processPaymentModal);
	}

	processPaymentList(){

		this.modalRef.hide();

		this.paymentDTO.supplierDTO.id = this.paymentItemSearchDTO.supplierDTO.id;
		this.paymentDTO.paymentItemList = [];

		for(let paymentId in this.selectedPaymentList){

			if(this.selectedPaymentList[paymentId] == true){

				this.paymentDTO.paymentItemList.push(this.paymentItemMap.get(Number(paymentId)));
			}
		}

		this.supplierService.processPayment(this.paymentDTO).subscribe((paymentDTO:PaymentDTO) => {

			this.selectedPaymentList = {};

			this.paymentDTO = paymentDTO;

			this.message = "Items has been paid with Payment ID : " + this.paymentDTO.id;

			this.paymentDTO = new PaymentDTO();
			
			setTimeout(()=>{

				this.message = null;

			}, 5000);

			this.searchPaymentItems();
		});
	}
}
