import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {CustomerDTO} from '../../models/customer';
import {CreditHistoryDTO} from '../../models/credit-history';
import {CustomerService} from '../../services/customer.service';
import { AddressDTO } from '../../models/address';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

	loading:boolean = false;
	
	mode:string = "list";

	customerList:CustomerDTO[] = [];
	addressList:AddressDTO[] = [];

	customerDTO:CustomerDTO = new CustomerDTO();
	customerSearchDTO:CustomerDTO = new CustomerDTO();
	creditHistoryList:CreditHistoryDTO[] =[];
	creditHistoryDTO:CreditHistoryDTO = new CreditHistoryDTO();

	@ViewChild("addCustomerCreditModal") addCustomerCreditModal:TemplateRef<any>;

	modalRef: BsModalRef;
	
	constructor(public customerService:CustomerService, private modalService: BsModalService) {
	}

	ngOnInit(): void {

		this.searchCustomers();
	}

	updateBalance(){

		this.creditHistoryDTO.balance = Number(this.customerDTO.creditAmount.toFixed(3));

		if(this.creditHistoryDTO.amount != null && this.creditHistoryDTO.amount != 0){

			this.creditHistoryDTO.balance += Number(this.creditHistoryDTO.amount); 
			this.creditHistoryDTO.balance = Number(this.creditHistoryDTO.balance.toFixed(3));
		}
	}

	searchCustomers(){

		this.loading = true;

		this.mode = "list";

		this.customerService.searchCustomers(this.customerSearchDTO).subscribe((customerList:CustomerDTO[]) => {

			this.loading = false;

			this.customerList = customerList;
		});
	}

	exportCustomers(){

		this.customerService.exportCustomers(this.customerSearchDTO);
	}

	viewCreditHistory(customerDTO:CustomerDTO){

		this.customerDTO = customerDTO;

		this.mode = "creditHistoryList";

		this.creditHistoryList = [];
		
		this.creditHistoryDTO.transaction = "Hulool Funds";

		this.creditHistoryDTO.customerDTO = customerDTO;
		
		this.updateBalance();

		this.customerService.viewCreditHistory(customerDTO).subscribe((creditHistoryList:CreditHistoryDTO[]) => {
			
			this.creditHistoryList = creditHistoryList;
		});
	}

	viewAddressList(customerDTO:CustomerDTO){

		this.mode = "addressList";

		this.addressList = [];
		
		this.customerService.viewAddressList(customerDTO).subscribe((addressList:AddressDTO[]) => {

			this.addressList = addressList;
		});
	}

	openAddCustomerCreditModal(){

		this.modalRef = this.modalService.show(this.addCustomerCreditModal);
	}

	addCustomerCredit(){

		this.modalRef.hide();

		this.customerService.addHuloolCredit(this.creditHistoryDTO).subscribe((creditHistoryDTO:CreditHistoryDTO)=>{

			this.creditHistoryDTO = creditHistoryDTO;

			this.viewCreditHistory(this.customerDTO);
		})
	}
}
