import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import {WorkCategoryService} from '../../services/work-category.service';
import {WorkCategoryDTO} from '../../models/work-category';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-work-category',
  templateUrl: './work-category.component.html',
  styleUrls: ['./work-category.component.css']
})
export class WorkCategoryComponent implements OnInit {
  
	message:string = null;
	loading:boolean = false;
	workCategoryList:WorkCategoryDTO[] = [];
	workCategoryDTO:WorkCategoryDTO = new WorkCategoryDTO();
	workCategorySearchDTO:WorkCategoryDTO = new WorkCategoryDTO();
	mode:string = "";

	@ViewChild("saveWorkCategoryModal") saveWorkCategoryModal:TemplateRef<any>;
	@ViewChild("activateWorkCategoryModal") activateWorkCategoryModal:TemplateRef<any>;
	@ViewChild("deactivateWorkCategoryModal") deactivateWorkCategoryModal:TemplateRef<any>;
	@ViewChild("activateWorkCategoryForCustomerModal") activateWorkCategoryForCustomerModal:TemplateRef<any>;
	@ViewChild("deactivateWorkCategoryForCustomerModal") deactivateWorkCategoryForCustomerModal:TemplateRef<any>;
	
	modalRef: BsModalRef;
	
	constructor(private workCategoryService:WorkCategoryService,
				private modalService: BsModalService) {
	}

	ngOnInit(): void {

		this.searchWorkCategoryList();
	}

	searchWorkCategoryList(){

		this.loading = true;

		this.mode = "list";
		
		this.workCategoryService.searchWorkCategoryList(this.workCategorySearchDTO).subscribe((workCategoryList:WorkCategoryDTO[])=> {

			this.loading = false;
			console.log(workCategoryList);
			this.workCategoryList = workCategoryList;
		});
	}

	exportWorkCategories(){

		this.workCategoryService.exportWorkCategories(this.workCategorySearchDTO);
	}

	editWorkCategory(workCategoryDTO:WorkCategoryDTO){

		this.mode = "edit";

		this.workCategoryDTO = workCategoryDTO;
	}

	addWorkCategory(){

		this.mode = "edit";

		this.workCategoryDTO = new WorkCategoryDTO();
	}

	openActiveateWorkCategoryModal(workCategoryDTO:WorkCategoryDTO){

		this.workCategoryDTO = workCategoryDTO;

		this.modalRef = this.modalService.show(this.activateWorkCategoryModal);
	}

	openDeactiveateWorkCategoryModal(workCategoryDTO:WorkCategoryDTO){

		this.workCategoryDTO = workCategoryDTO;

		this.modalRef = this.modalService.show(this.deactivateWorkCategoryModal);
	}

	openActiveateWorkCategoryForCustomerModal(workCategoryDTO:WorkCategoryDTO){

		this.workCategoryDTO = workCategoryDTO;

		this.modalRef = this.modalService.show(this.activateWorkCategoryForCustomerModal);
	}

	openDeactiveateWorkCategoryForCustomerModal(workCategoryDTO:WorkCategoryDTO){

		this.workCategoryDTO = workCategoryDTO;

		this.modalRef = this.modalService.show(this.deactivateWorkCategoryForCustomerModal);
	}

	openSaveWorkCategoryModal() {
	
		this.modalRef = this.modalService.show(this.saveWorkCategoryModal);
	}

	updateImage(event:any){

		if(event.target.files && event.target.files[0]) {

			var reader = new FileReader();

			reader.onload = (event:any) => {

				this.workCategoryDTO.photo = event.target.result;
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}

	activateWorkCategory(){

		this.modalRef.hide();

		this.workCategoryService.activateWorkCategory(this.workCategoryDTO).subscribe((workCategoryDTO:WorkCategoryDTO)=>{

			this.message = "Work Category " + workCategoryDTO.id + " has been activated successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchWorkCategoryList();
		});
	}

	activateWorkCategoryForCustomer(){

		this.modalRef.hide();

		this.workCategoryService.activateWorkCategoryForCustomer(this.workCategoryDTO).subscribe((workCategoryDTO:WorkCategoryDTO)=>{

			this.message = "Work Category " + workCategoryDTO.id + " has been activated for customer successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchWorkCategoryList();
		});
	}

	deactivateWorkCategory(){

		this.modalRef.hide();
		
		this.workCategoryService.deactivateWorkCategory(this.workCategoryDTO).subscribe((workCategoryDTO:WorkCategoryDTO)=>{

			this.message = "Work Category " + workCategoryDTO.id + " has been deactivated successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchWorkCategoryList();
		});
	}

	deactivateWorkCategoryForCustomer(){

		this.modalRef.hide();
		
		this.workCategoryService.deactivateWorkCategoryForCustomer(this.workCategoryDTO).subscribe((workCategoryDTO:WorkCategoryDTO)=>{

			this.message = "Work Category " + workCategoryDTO.id + " has been deactivated for customer successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchWorkCategoryList();
		});
	}

	saveWorkCategory(){

		this.modalRef.hide();

		this.workCategoryService.saveWorkCategory(this.workCategoryDTO).subscribe((workCategoryDTO:WorkCategoryDTO)=>{

			this.message = "Work Category " + workCategoryDTO.id + " has been saved successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchWorkCategoryList();
		});
	}
}
