import {JobSchedulerHistoryDTO} from './job-scheduler-history';
import {CommonDTO} from './common';

export class BaseJobSchedulerDTO extends CommonDTO {

	id:number;
	englishTitle:string;
	arabicTitle:string;
	url:string;
	lastRecordCount:number;
	lastExecutionTime:string;
	lastExecutionLog:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	jobSchedulerHistoryList:Array<JobSchedulerHistoryDTO>;
}