import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
 
import { LoginComponent }   from './components/login/login.component';
import { HomeComponent }   from './components/home/home.component';
import {VerifySupplierComponent }   from './components/verify-supplier/verify-supplier.component';
import {OfferComponent }   from './components/offer/offer.component';
import {AdvertisorComponent }   from './components/advertisor/advertisor.component';
import {CustomerComponent }   from './components/customer/customer.component';
import {WorkRequestComponent }   from './components/work-request/work-request.component';
import {UserComponent }   from './components/user/user.component';
import {WorkOrderComponent }   from './components/work-order/work-order.component';
import {WorkQuatationComponent }   from './components/work-quatation/work-quatation.component';
import {ParameterComponent }   from './components/parameter/parameter.component';
import {AboutComponent }   from './components/about/about.component';
import {ContactComponent }   from './components/contact/contact.component';
import {CommunicationComponent }   from './components/communication/communication.component';
import {SupplierSpecializationComponent }   from './components/supplier-specialization/supplier-specialization.component';
import {StatusComponent} from './components/status/status.component';
import {WorkCategoryComponent} from './components/work-category/work-category.component';
import {WorkSubCategoryComponent} from './components/work-sub-category/work-sub-category.component';
import {MonthlyReconciliationComponent} from './components/monthly-reconciliation/monthly-reconciliation.component';
import { PurchaseOrderComponent } from './components/purchase-order/purchase-order.component';
import { PaymentComponent } from './components/payment/payment.component';
import { NotificationComponent } from './components/notification/notification.component';
import { WorkBidComponent } from './components/work-bid/work-bid.component';
import { JobScheduleComponent } from './components/job-schedule/job-schedule.component';
import { OnlinePaymentComponent } from './components/online-payment/online-payment.component';
import { SupplierSpecializationPriceComponent } from './components/supplier-specialization-price/supplier-specialization-price.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'verify-supplier', component:VerifySupplierComponent},
  {path: 'offers', component:OfferComponent},
  {path: 'advertisers', component:AdvertisorComponent},
  {path: 'customers', component:CustomerComponent},
  {path: 'work-requests', component:WorkRequestComponent},
  {path: 'work-orders', component:WorkOrderComponent},
  {path: 'work-quatations', component:WorkQuatationComponent},
  {path: 'purchase-order', component:PurchaseOrderComponent},
  {path: 'users', component:UserComponent},
  {path: 'parameters', component:ParameterComponent},
  {path: 'contact', component:ContactComponent},
  {path: 'about', component:AboutComponent},
  {path: 'communication', component:CommunicationComponent},
  {path: 'supplier-specializations', component:SupplierSpecializationComponent},
  {path: 'supplier-specialization-price', component:SupplierSpecializationPriceComponent},
  {path: 'statuses', component:StatusComponent},
  {path: 'work-categories', component:WorkCategoryComponent},
  {path: 'work-sub-categories', component:WorkSubCategoryComponent},
  {path: 'monthly-reconciliation', component:MonthlyReconciliationComponent},
  {path: 'payment', component:PaymentComponent},
  {path: 'notifications', component:NotificationComponent},
  {path: 'work-bids', component:WorkBidComponent},
  {path: 'job-schedule', component:JobScheduleComponent},
  {path: 'online-payment', component:OnlinePaymentComponent}
];
 
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}