import {RoleDTO} from './role';
import {StatusDTO} from './status';
import {NotificationDTO} from './notification';
import {SupplierDTO} from './supplier';
import {LoginDTO} from './login';
import {DeviceDTO} from './device';
import {CustomerDTO} from './customer';
import {CommonDTO} from './common';

export class BaseUserDTO extends CommonDTO {

	id:number;
	email:string;
	mobileExt:string;
	mobileNo:number;
	password:string;
	statusDTO:StatusDTO = new StatusDTO();
	roleDTO:RoleDTO = new RoleDTO();
	registerationTime:string;
	language:string;
	verificationCode:number;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	notificationList:Array<NotificationDTO>;
	supplierList:Array<SupplierDTO>;
	loginList:Array<LoginDTO>;
	deviceList:Array<DeviceDTO>;
	customerList:Array<CustomerDTO>;
}