import {SupplierDTO} from './supplier';
import {PaymentItemDTO} from './payment-item';
import {CommonDTO} from './common';

export class BasePaymentDTO extends CommonDTO {

	id:number;
	supplierDTO:SupplierDTO = new SupplierDTO();
	chequeNo:number;
	transactionRef:string;
	amount:number;
	paymentTime:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	paymentItemList:Array<PaymentItemDTO>;
}