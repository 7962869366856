import {PaymentItemDTO} from './payment-item';
import {NotificationDTO} from './notification';
import {SupplierSpecializationDTO} from './supplier-specialization';
import {SupplierDTO} from './supplier';
import {WorkRequestBidDTO} from './work-request-bid';
import {WorkRequestDTO} from './work-request';
import {SupplierSpecializationPriceDTO} from './supplier-specialization-price';
import {UserDTO} from './user';
import {WorkRequestHistoryDTO} from './work-request-history';
import {CommonDTO} from './common';

export class BaseStatusDTO extends CommonDTO {

	id:number;
	englishTitle:string;
	arabicTitle:string;
	customerEnglishTitle:string;
	customerArabicTitle:string;
	supplierEnglishTitle:string;
	supplierArabicTitle:string;
	description:string;
	category:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	paymentItemList:Array<PaymentItemDTO>;
	notificationList:Array<NotificationDTO>;
	supplierSpecializationList:Array<SupplierSpecializationDTO>;
	supplierList:Array<SupplierDTO>;
	workRequestBidList:Array<WorkRequestBidDTO>;
	workRequestList:Array<WorkRequestDTO>;
	supplierSpecializationPriceList:Array<SupplierSpecializationPriceDTO>;
	userList:Array<UserDTO>;
	workRequestHistoryList:Array<WorkRequestHistoryDTO>;
}