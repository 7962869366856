import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import {ParameterService} from '../../services/parameter.service';
import {ParameterDTO} from '../../models/parameter';
import {Router} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.css']
})
export class ParameterComponent implements OnInit {

	message:string = null;
	loading:boolean = false;
	parameterList:ParameterDTO[] = [];
	parameterDTO:ParameterDTO = new ParameterDTO();
	parameterSearchDTO:ParameterDTO = new ParameterDTO();
	mode:string = "";

	@ViewChild("saveParameterModal") saveParameterModal:TemplateRef<any>;
	
	modalRef: BsModalRef;
	
	constructor(public parameterService:ParameterService, 
				public router: Router,
				private modalService: BsModalService) {
	}

	ngOnInit(): void {

		this.searchParameters();
	}

	searchParameters(){

		this.loading = true;

		this.mode = "list";
		
		this.parameterService.searchParameters(this.parameterSearchDTO).subscribe((parameterList:ParameterDTO[]) =>{

			this.loading = false;

			this.parameterList = parameterList;
		});
	}

	exportParameters(){

		this.parameterService.exportParameters(this.parameterSearchDTO);
	}

	editParameter(parameterDTO:ParameterDTO){

		this.mode = "edit";
		
		this.parameterDTO = parameterDTO;
	}

	openUpdateParameterModal() {
		
		this.modalRef = this.modalService.show(this.saveParameterModal);
	}

	updateParameter(){

		this.modalRef.hide();

		this.parameterService.updateParameter(this.parameterDTO).subscribe((parameterDTO:ParameterDTO) =>{
		
			this.message = "Parameter " + parameterDTO.id + " has been saved successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchParameters();
		});
	}
}
