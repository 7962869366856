import { Injectable } from '@angular/core';
import {Api} from './api.service';
import {Observable} from 'rxjs/Observable';
import {WorkRequestDTO} from '../models/work-request';
import {WorkRequestBidDTO} from '../models/work-request-bid';
import {WorkRequestHistoryDTO} from '../models/work-request-history';
import {WorkOrderDTO} from '../models/work-order';
import {WorkQuatationDTO} from '../models/work-quatation';
import { PurchaseOrderDTO } from '../models/purchase-order';

@Injectable()
export class WorkRequestService {

	constructor(public api: Api) {

	}

	searchWorkRequests(workRequestSearchDTO:WorkRequestDTO) : Observable<WorkRequestDTO[]>{

		return this.api.post("searchWorkRequests", workRequestSearchDTO);
	}

	exportWorkRequests(workRequestSearchDTO:WorkRequestDTO) : void{

		return this.api.download("exportWorkRequests", workRequestSearchDTO, "Work Requests");
	}

	fetchWorkRequestHistory(workRequestDTO:WorkRequestDTO) : Observable<WorkRequestHistoryDTO[]>{

		return this.api.get("fetchWorkRequestHistory/" + workRequestDTO.id);
	}

	searchWorkBids(workRequestBidSearchDTO:WorkRequestBidDTO) : Observable<WorkRequestBidDTO[]>{

		return this.api.post("searchWorkBids", workRequestBidSearchDTO);
	}

	exportWorkBids(workRequestBidSearchDTO:WorkRequestBidDTO) : void{

		this.api.download("exportWorkBids", workRequestBidSearchDTO, "Work Bids");
	}

	searchWorkOrders(workOrderSearchDTO:WorkOrderDTO) : Observable<WorkOrderDTO[]>{

		return this.api.post("searchWorkOrders", workOrderSearchDTO);
	}

	exportWorkOrders(workOrderSearchDTO:WorkOrderDTO) : void{

		this.api.download("exportWorkOrders", workOrderSearchDTO, "Work Orders");
	}

	searchWorkQuatations(workQuatationSearchDTO:WorkQuatationDTO) : Observable<WorkQuatationDTO[]>{

		return this.api.post("searchWorkQuatations", workQuatationSearchDTO);
	}

	exportWorkQuatations(workQuatationSearchDTO:WorkQuatationDTO) : void{

		return this.api.download("exportWorkQuatations", workQuatationSearchDTO, "Work Quatations");
	}

	searchPurchaseOrders(purchaseOrderSearchDTO:PurchaseOrderDTO) : Observable<PurchaseOrderDTO[]>{

		return this.api.post("searchPurchaseOrders", purchaseOrderSearchDTO);
	}

	exportPurchaseOrders(purchaseOrderSearchDTO:PurchaseOrderDTO) : void{

		return this.api.download("exportPurchaseOrders", purchaseOrderSearchDTO, "Purchase Orders");
	}

	getStatusById(status:number){

		return this.api.get("getStatusById/" + status);
	}

	cancelWorkRequestByHulool(workRequestDTO:WorkRequestDTO) : Observable<WorkRequestDTO>{

		return this.api.post("cancelWorkRequestByHulool", workRequestDTO);
	}

	cancelPurchaseOrderByHulool(purchaseOrderDTO:PurchaseOrderDTO) : Observable<PurchaseOrderDTO>{

		return this.api.post("cancelPurchaseOrderByHulool", purchaseOrderDTO);
	}

	getWorkOrderByWorkRequest(workRequestDTO:WorkRequestDTO) : Observable<WorkOrderDTO>{

        return this.api.post("getWorkOrderByWorkRequest", workRequestDTO);
    }

    getWorkQuatationByWorkRequest(workRequestDTO:WorkRequestDTO) : Observable<WorkQuatationDTO>{

        return this.api.post("getWorkQuatationByWorkRequest", workRequestDTO);
    }

    getPurchaseOrderByWorkRequest(workRequestDTO:WorkRequestDTO) : Observable<PurchaseOrderDTO>{

        return this.api.post("getPurchaseOrderByWorkRequest", workRequestDTO);
    }
}
