import { Component, ViewChild,ElementRef,TemplateRef, OnInit } from '@angular/core';
import {SupplierDTO} from '../../models/supplier';
import {SupplierService} from '../../services/supplier.service';
import {StatusDTO} from '../../models/status';
import {HuloolConstants} from '../../hulool.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RoleDTO } from '../../models/role';
import { UserService } from '../../services/user.service';
import { CommonComponent } from '../common.component';

@Component({
  selector: 'app-verify-supplier',
  templateUrl: './verify-supplier.component.html',
  styleUrls: ['./verify-supplier.component.css']
})
export class VerifySupplierComponent  extends CommonComponent implements OnInit  {

	message:string = null;
	loading:boolean = false;
	supplierList:SupplierDTO[] = [];
	supplierDTO:SupplierDTO = new SupplierDTO();
	supplierSearchDTO:SupplierDTO = new SupplierDTO();
	registeredSupplier:number = HuloolConstants.SUPPLIER_REGISTERED;
	approvedSupplier:number = HuloolConstants.SUPPLIER_APPROVED;
	rejectedSupplier:number = HuloolConstants.SUPPLIER_REJECTED;
	suspendedSupplier:number = HuloolConstants.SUPPLIER_SUSPENDED;
	mode:string = "list";
	mobileExist:boolean= false;
	statusList:StatusDTO[] = [];

	@ViewChild("updateHoloolCheckCostModal") updateHoloolCheckCostModal:TemplateRef<any>;
	@ViewChild("approveSupplierModal") approveSupplierModal:TemplateRef<any>;
	@ViewChild("rejectSupplierModal") rejectSupplierModal:TemplateRef<any>;
	@ViewChild("suspendSupplierModal") suspendSupplierModal:TemplateRef<any>;
	@ViewChild("updateSupplierFullDetailsModal") updateSupplierFullDetailsModal:TemplateRef<any>;
	@ViewChild("supplierMobileExistsModal") supplierMobileExistsModal:TemplateRef<any>;
	@ViewChild("crExpiryDate") crExpiryDate:ElementRef;
	@ViewChild("crExpiryStartDate") crExpiryStartDate:ElementRef;
	@ViewChild("crExpiryEndDate") crExpiryEndDate:ElementRef;
	modalRef: BsModalRef;

	constructor(private supplierService:SupplierService,
				private userService:UserService,
				private modalService: BsModalService) {
					super();
	}

	ngOnInit(): void {

		this.supplierService.getStatusById(HuloolConstants.SUPPLIER_REGISTERED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SUPPLIER_APPROVED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SUPPLIER_REJECTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SUPPLIER_SUSPENDED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		// let date:Date = new Date();

		// date.setDate(date.getDate() - 7);

		// let day:string = String(date.getDate());
		// let month:string = String(date.getMonth() + 1);
		// let year:string = String(date.getFullYear());

		// this.crExpiryStartDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		// date = new Date();
		// day = String(date.getDate());
		// month = String(date.getMonth() + 1);
		// year = String(date.getFullYear());

		// this.crExpiryEndDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;


		this.searchSuppliers();

		$(".datePicker").each(function(){

            $(this).datepicker({format: "dd-mm-yyyy", uiLibrary: "bootstrap4"});
        })
	}

	searchSuppliers(){

		this.loading = true;

		this.mode = "list";

		this.supplierSearchDTO.crExpiryStartDate= this.crExpiryStartDate.nativeElement.value;
		this.supplierSearchDTO.crExpiryEndDate = this.crExpiryEndDate.nativeElement.value;
		this.supplierService.searchSuppliers(this.supplierSearchDTO).subscribe((supplierList:SupplierDTO[]) => {

			this.loading = false;

			this.supplierList = supplierList;
		});
	}

	exportSuppliers(){

		this.supplierService.exportSuppliers(this.supplierSearchDTO);
	}

	viewSupplier(supplierDTO:SupplierDTO){

		this.mode = "view";

		this.supplierDTO = supplierDTO;
	}

	editSupplier(supplierDTO:SupplierDTO){

		this.mode = "edit";
		this.mobileExist= false;
		if(supplierDTO.telephoneNo ==0)
			supplierDTO.telephoneNo = null; 

		this.supplierDTO = supplierDTO;
	}

	openUpdateHuloolCheckCostModal(){

		this.modalRef = this.modalService.show(this.updateHoloolCheckCostModal);
	}

	openApproveSupplierModal(){

		this.modalRef = this.modalService.show(this.approveSupplierModal);
	}

	openRejectSupplierModal(){

		this.modalRef = this.modalService.show(this.rejectSupplierModal);
	}

	openSuspendSupplierModal(){

		this.modalRef = this.modalService.show(this.suspendSupplierModal);
	}

	openUpdateSupplierDetailsModal(){
		if(this.mobileExist){
			this.modalRef = this.modalService.show(this.supplierMobileExistsModal);
		}else{
			this.modalRef = this.modalService.show(this.updateSupplierFullDetailsModal);
		}

	}
	approveSupplier(){
		
		this.modalRef.hide();

		this.supplierService.approveSupplier(this.supplierDTO).subscribe(() => {

			this.message = "Supplier " + this.supplierDTO.id + " has been approved successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSuppliers();
		});
	}

	rejectSupplier(){
		
		this.modalRef.hide();

		this.supplierService.rejectSupplier(this.supplierDTO).subscribe(() => {

			this.message = "Supplier " + this.supplierDTO.id + " has been rejected successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSuppliers();
		});
	}

	suspendSupplier(){
		
		this.modalRef.hide();

		this.supplierService.suspendSupplier(this.supplierDTO).subscribe(() => {

			this.message = "Supplier " + this.supplierDTO.id + " has been suspended successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSuppliers();
		});
	}

	updateHuloolCheckCost(){
		
		this.modalRef.hide();

		this.supplierService.updateHuloolCheckCost(this.supplierDTO).subscribe(() => {

			this.message = "Hulool Cost of Supplier " + this.supplierDTO.id + " has been updated successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSuppliers();
		});
	}

	updateSupplierLogoImage(event:any){

		if(event.target.files && event.target.files[0]) {

			var reader = new FileReader();

			reader.onload = (event:any) => {

				this.supplierDTO.logo = event.target.result;
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}

	updateSuppliercommercialCertificate(event:any){

		if(event.target.files && event.target.files[0]) {

			var reader = new FileReader();

			reader.onload = (event:any) => {
				//console.log(event.target.result);
				this.supplierDTO.commercialCertificate = event.target.result;
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}
	

	checkSupplierMobileExistance(){
	
		if(this.supplierDTO.mobileNo != null && this.supplierDTO.mobileNo != 0 && String(this.supplierDTO.mobileNo) != ""){

			let roleDTO:RoleDTO = new RoleDTO();
			roleDTO.id = HuloolConstants.SUPPLIER_ROLE_ID;
			this.userService.checkMobileExistanceByOthers(this.supplierDTO.mobileNo, this.supplierDTO.id , roleDTO).subscribe((exist:boolean) => {
				this.mobileExist= exist;
			});
		}
	}

	updateSupplierDetails(){
		if(!this.mobileExist){
		
			this.modalRef.hide();

			this.supplierDTO.crExpiryDate = this.crExpiryDate.nativeElement.value;
			this.supplierService.updateSupplierDetails(this.supplierDTO).subscribe(() => {

				this.message = "Supplier " + this.supplierDTO.id + " has been updated successfully";

				setTimeout(()=>{

					this.message = null;

				}, 3000);

				this.searchSuppliers();
			});
		}
	}

	cancelUpdate(){
		this.mode = 'list';
		this.searchSuppliers();
	}
	
	toHTML(value:string) : string{

		if(value != null){

			return value.replace(/\n/g, "<br>");
		}

		return null;
	}

	downloadCommercialCertificatePDF(){
		let searchSupplierDTO = new SupplierDTO();
		searchSupplierDTO.id = this.supplierDTO.id
		this.supplierService.exportCommercialCertificateDocument(searchSupplierDTO);

	}
}
