import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { VerifySupplierComponent } from './components/verify-supplier/verify-supplier.component';

import {SupplierService} from './services/supplier.service';
import {OfferService} from './services/offer.service';
import {CustomerService} from './services/customer.service';
import {WorkRequestService} from './services/work-request.service';
import {UserService} from './services/user.service';
import {ParameterService} from './services/parameter.service';
import {CommunicationService} from './services/communication.service';
import {StatusService} from './services//status.service';
import {WorkCategoryService} from './services/work-category.service';
import {WorkSubCategoryService} from './services/work-sub-category.service';
import {Api} from './services//api.service';

import { OfferComponent } from './components/offer/offer.component';
import { AdvertisorComponent } from './components/advertisor/advertisor.component';
import { CustomerComponent } from './components/customer/customer.component';
import { WorkRequestComponent } from './components/work-request/work-request.component';
import { WorkOrderComponent } from './components/work-order/work-order.component';
import { UserComponent } from './components/user/user.component';
import { ParameterComponent } from './components/parameter/parameter.component';
import { SupplierSpecializationComponent } from './components/supplier-specialization/supplier-specialization.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { CommunicationComponent } from './components/communication/communication.component';
import { StatusComponent } from './components/status/status.component';
import { WorkCategoryComponent } from './components/work-category/work-category.component';
import { WorkSubCategoryComponent } from './components/work-sub-category/work-sub-category.component';
import { WorkQuatationComponent } from './components/work-quatation/work-quatation.component';
import { MonthlyReconciliationComponent } from './components/monthly-reconciliation/monthly-reconciliation.component';
import { PurchaseOrderComponent } from './components/purchase-order/purchase-order.component';
import { PaymentComponent } from './components/payment/payment.component';
import { NotificationComponent } from './components/notification/notification.component';
import { WorkBidComponent } from './components/work-bid/work-bid.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from './services/data.service';
import { JobScheduleComponent } from './components/job-schedule/job-schedule.component';
import { OnlinePaymentComponent } from './components/online-payment/online-payment.component';
import { CommonComponent } from './components/common.component';
import { SupplierSpecializationPriceComponent } from './components/supplier-specialization-price/supplier-specialization-price.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

/**
 * 
 * PDF viewer currently uses version 3 only which is compatible with Hulool Angular version.
 * Consider update this component after updgrade angular version.
 * 
 * https://www.npmjs.com/package/ng2-pdf-viewer
 * https://github.com/vadimdez/ng2-pdf-viewer
 * https://remotestack.io/how-to-implement-and-display-pdf-viewer-in-angular/
 */


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  imports:      [ BrowserModule, 
                  FormsModule,
                  HttpClientModule,
                  ModalModule.forRoot(),
                  TranslateModule.forRoot({
                      loader: {
                          provide: TranslateLoader,
                          useFactory: HttpLoaderFactory,
                          deps: [HttpClient]
                      }
                  }),
                  AppRoutingModule
                  ,PdfViewerModule
                ],
  declarations: [ AppComponent, LoginComponent, HomeComponent, 
                    VerifySupplierComponent, OfferComponent, AdvertisorComponent, 
                    CustomerComponent, WorkRequestComponent, WorkOrderComponent, SupplierSpecializationPriceComponent ,
                    UserComponent, ParameterComponent, SupplierSpecializationComponent, AboutComponent, ContactComponent, CommunicationComponent, StatusComponent, WorkCategoryComponent, WorkSubCategoryComponent, WorkQuatationComponent, MonthlyReconciliationComponent, PurchaseOrderComponent, PaymentComponent, NotificationComponent, WorkBidComponent, JobScheduleComponent, OnlinePaymentComponent,CommonComponent ],
  bootstrap:    [ AppComponent ],
  providers:    [ StatusService, WorkCategoryService, WorkSubCategoryService, SupplierService, OfferService, CustomerService, WorkRequestService, UserService, ParameterService, CommunicationService, Api, DataService]
})
export class AppModule {
    
}
