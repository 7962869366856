import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import {SupplierService} from '../../services/supplier.service';
import {SupplierSpecializationDTO} from '../../models/supplier-specialization';
import {StatusDTO} from '../../models/status';
import {HuloolConstants} from '../../hulool.constants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WorkCategoryDTO } from '../../models/work-category';
import { WorkCategoryService } from '../../services/work-category.service';

@Component({
  selector: 'app-supplier-specialization',
  templateUrl: './supplier-specialization.component.html',
  styleUrls: ['./supplier-specialization.component.css']
})
export class SupplierSpecializationComponent implements OnInit {

	message:string = null;
	loading:boolean = false;
	workCategoryList:WorkCategoryDTO[] = [];
	specializationDTO:SupplierSpecializationDTO = new SupplierSpecializationDTO();
	specializationSearchDTO:SupplierSpecializationDTO = new SupplierSpecializationDTO();
	specializationList:SupplierSpecializationDTO[] = [];
	statusList:StatusDTO[] = [];
	specializationSubmitted:number = HuloolConstants.SPECIALIZATION_SUBMITTED;
	specializationApproved:number = HuloolConstants.SPECIALIZATION_APPROVED;
	specializationRejected:number = HuloolConstants.SPECIALIZATION_REJECTED;

	rejectionReasonList:Array<number> = new Array<number>();

	@ViewChild("approveSpecializationModal") approveSpecializationModal:TemplateRef<any>;
	@ViewChild("rejectSpecializationModal") rejectSpecializationModal:TemplateRef<any>;
	
	modalRef: BsModalRef;

	constructor(public supplierService:SupplierService,
				public workCategoryService:WorkCategoryService,
				private modalService: BsModalService) {
	}

	ngOnInit(): void {

		let workCategoryDTO:WorkCategoryDTO = new WorkCategoryDTO();
		workCategoryDTO.active = true;
		
		this.workCategoryService.searchWorkCategoryList(workCategoryDTO).subscribe((workCategoryList:WorkCategoryDTO[]) => {

			this.workCategoryList = workCategoryList;
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_SUBMITTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_APPROVED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_REJECTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.supplierService.getStatusById(HuloolConstants.SPECIALIZATION_DELETED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.searchSupplierSpecialization();
	}

	searchSupplierSpecialization(){

		this.loading = true;

		this.supplierService.searchSpecializationList(this.specializationSearchDTO).subscribe((specializationList:SupplierSpecializationDTO[]) => {
		
			this.loading = false;

			this.specializationList = specializationList;
		});
	}

	exportSpecializations(){

		this.supplierService.exportSpecializations(this.specializationSearchDTO);
	}

	openApproveSpecializationModal(specializationDTO:SupplierSpecializationDTO){

		this.specializationDTO = specializationDTO;

		this.modalRef = this.modalService.show(this.approveSpecializationModal);
	}

	openRejectSpecializationModal(specializationDTO:SupplierSpecializationDTO){

		this.specializationDTO = specializationDTO;

		this.modalRef = this.modalService.show(this.rejectSpecializationModal);
	}

	approveSpecialization(){

		this.modalRef.hide();

		this.supplierService.approveSpecialization(this.specializationDTO).subscribe(() => {

			this.message = "Specialization " + this.specializationDTO.id + " has been approved successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSupplierSpecialization();
		});
	}

	toRejectSpecialization(specializationDTO:SupplierSpecializationDTO){

		this.specializationDTO = specializationDTO;

		this.rejectionReasonList.push(specializationDTO.id);
	}

	cancelRejectSpecialization(specializationDTO:SupplierSpecializationDTO){

		this.rejectionReasonList.splice(this.rejectionReasonList.indexOf(specializationDTO.id), 1);
	}

	rejectSpecialization(){

		this.rejectionReasonList.splice(this.rejectionReasonList.indexOf(this.specializationDTO.id), 1);

		this.modalRef.hide();

		this.supplierService.rejectSpecialization(this.specializationDTO).subscribe(() => {

			this.message = "Specialization " + this.specializationDTO.id + " has been rejected successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchSupplierSpecialization();
		});
	}
}
