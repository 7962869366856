import { Injectable } from '@angular/core';
import {Api} from './api.service';
import {Observable} from 'rxjs/Observable';
import {UserDTO} from '../models/user';
import {RoleDTO} from '../models/role';

@Injectable()
export class UserService {

	constructor(public api: Api) {

	}

	toConsultPro() : Observable<string> {

		return this.api.getString("toConsultPro");
	}

	getRoleList() : Observable<RoleDTO[]> {

		return this.api.get("getRoleList");
	}

	searchUsers(userSearchDTO:UserDTO) : Observable<UserDTO[]> {

		return this.api.post("searchUsers", userSearchDTO);
	}

	exportUsers(userSearchDTO:UserDTO) : void{

		this.api.download("exportUsers", userSearchDTO, "Users");
	}

	login(userDTO:UserDTO) : Observable<UserDTO>{

		return this.api.post("login", userDTO);
	}

	lockAccount(userDTO:UserDTO){

		return this.api.get("lockAccount/" + userDTO.id);
	}

	unlockAccount(userDTO:UserDTO){
		
		return this.api.get("unlockAccount/" + userDTO.id);
	}

	resendVerificationCode(userDTO:UserDTO) : Observable<UserDTO>{
		
		return this.api.post("resendVerificationCode", userDTO);
    }
    
    resetUserPassword(userDTO:UserDTO) : Observable<UserDTO>{
		
		return this.api.post("resetUserPassword", userDTO);
	}

	checkMobileExistanceByOthers(mobile:number, userId:number , roleDTO:RoleDTO) : Observable<boolean> {

        let userDTO:UserDTO = new UserDTO();
        userDTO.mobileNo = mobile;
        userDTO.id = userId;
        userDTO.roleDTO = roleDTO;
        return this.api.post("checkUserExistance", userDTO);
    }
}
