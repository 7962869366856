import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {UserDTO} from '../../models/user';
import {RoleDTO} from '../../models/role';
import {HuloolConstants} from '../../hulool.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StatusDTO } from '../../models/status';
import { WorkRequestService } from '../../services/work-request.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

	message:string = null;
	loading:boolean = false;
	roleList:RoleDTO[] = [];
	userList:UserDTO[] = [];
	userSearchDTO:UserDTO = new UserDTO();
	userDTO:UserDTO = new UserDTO();
	activeUser:number = HuloolConstants.USER_ACTIVE;
	lockedUser:number = HuloolConstants.USER_LOCKED;

	statusList:StatusDTO[] = [];
	
	@ViewChild("lockUserModal") lockUserModal:TemplateRef<any>;
	@ViewChild("unlockUserModal") unlockUserModal:TemplateRef<any>;
	
	modalRef: BsModalRef;
	
	constructor(private userService:UserService,
				private modalService: BsModalService,
				private workRequestService:WorkRequestService) {		
	}

	ngOnInit(): void {

		this.workRequestService.getStatusById(HuloolConstants.USER_ACTIVE).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.USER_VERIFICATION_REQUIRED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.USER_LOCKED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.USER_CHANGE_PASSWORD_REQUIRED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.searchUsers();

		this.userService.getRoleList().subscribe((roleList:RoleDTO[]) => {

			this.roleList = roleList;
		});
	}

	searchUsers(){
		
		this.loading = true;

		this.userService.searchUsers(this.userSearchDTO).subscribe((userList:UserDTO[]) => {

			this.loading = false;

			this.userList = userList;
		});
	}

	exportUsers(){
		
		this.userService.exportUsers(this.userSearchDTO);
	}

	openLockUserModal(userDTO:UserDTO) {
		
		this.userDTO = userDTO;
		this.modalRef = this.modalService.show(this.lockUserModal);
	}

	openUnlockUserModal(userDTO:UserDTO) {
		
		this.userDTO = userDTO;
		this.modalRef = this.modalService.show(this.unlockUserModal);
	}

	lockAccount(){

		this.modalRef.hide();

		this.userService.lockAccount(this.userDTO).subscribe(() => {

			this.message = "User " + this.userDTO.email + " has been locked successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchUsers();
		});
	}

	unlockAccount(){

		this.modalRef.hide();

		this.userService.unlockAccount(this.userDTO).subscribe(() => {

			this.message = "User " + this.userDTO.email + " has been unlocked successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchUsers();
		});
	}

	resetPassword(userDTO:UserDTO){
		userDTO.roleDTO = new RoleDTO();
		userDTO.roleDTO.id = HuloolConstants.ADMIN_ROLE_ID;

		this.userService.resetUserPassword(userDTO).subscribe((userDTO:UserDTO)=>{

			this.message = "User Password has been reset successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);
			
			this.searchUsers();
		});
	}
}
