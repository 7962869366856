import {StatusDTO} from './status';
import {UserDTO} from './user';
import {AdvertiserDTO} from './advertiser';
import {PaymentItemDTO} from './payment-item';
import {SupplierSpecializationDTO} from './supplier-specialization';
import {PurchaseOrderDTO} from './purchase-order';
import {WorkQuatationDTO} from './work-quatation';
import {WorkRequestBidDTO} from './work-request-bid';
import {PaymentDTO} from './payment';
import {WorkRequestDTO} from './work-request';
import {WorkOrderDTO} from './work-order';

export class BaseSupplierDTO extends UserDTO {

	englishName:string;
	arabicName:string;
	statusDTO:StatusDTO = new StatusDTO();
	website:string;
	paymentMethod:string;
	jobTitle:string;
	jobDescription:string;
	telephoneExt:string;
	telephoneNo:number;
	license:string;
	responsibleMobileExt:string;
	responsibleMobileNo:number;
	responsibleName:string;
	rate:number;
	rateCount:number;
	kpi:number;
	huloolCheckCost:number;
	logo:string;
	commercialCertificate:string;
	rejectionReason:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
	preferencePoints:number;
	crExpiryDate:string;

	advertiserList:Array<AdvertiserDTO>;
	paymentItemList:Array<PaymentItemDTO>;
	supplierSpecializationList:Array<SupplierSpecializationDTO>;
	purchaseOrderList:Array<PurchaseOrderDTO>;
	workQuatationList:Array<WorkQuatationDTO>;
	workRequestBidList:Array<WorkRequestBidDTO>;
	paymentList:Array<PaymentDTO>;
	workRequestList:Array<WorkRequestDTO>;
	workOrderList:Array<WorkOrderDTO>;
}