import {SupplierSpecializationDTO} from './supplier-specialization';
import {WorkRequestDTO} from './work-request';
import {WorkSubCategoryDTO} from './work-sub-category';
import {CommonDTO} from './common';

export class BaseWorkCategoryDTO extends CommonDTO {

	id:number;
	englishTitle:string;
	arabicTitle:string;
	englishDescription:string;
	arabicDescription:string;
	photo:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
	showToCustomer:boolean;

	supplierSpecializationList:Array<SupplierSpecializationDTO>;
	workRequestList:Array<WorkRequestDTO>;
	workSubCategoryList:Array<WorkSubCategoryDTO>;
}