import { Injectable } from '@angular/core';
import {WorkCategoryDTO} from '../models/work-category';
import {Observable} from 'rxjs/Observable';
import {Api} from './api.service';

@Injectable()
export class WorkCategoryService {

	constructor(public api: Api) {
		
	}

	searchWorkCategoryList(workCategoryDTO:WorkCategoryDTO) : Observable<WorkCategoryDTO[]> {

		return this.api.post("searchWorkCategories", workCategoryDTO);
	}

	exportWorkCategories(workCategoryDTO:WorkCategoryDTO) : void {

		return this.api.download("exportWorkCategories", workCategoryDTO, "Work Categories");
	}

	activateWorkCategory(workCategoryDTO:WorkCategoryDTO) : Observable<WorkCategoryDTO>{

		return this.api.post("activateWorkCategory", workCategoryDTO);
	}

	deactivateWorkCategory(workCategoryDTO:WorkCategoryDTO) : Observable<WorkCategoryDTO>{
		
		return this.api.post("deactivateWorkCategory", workCategoryDTO);
	}
	

	activateWorkCategoryForCustomer(workCategoryDTO:WorkCategoryDTO) : Observable<WorkCategoryDTO>{

		return this.api.post("activateWorkCategoryForCustomer", workCategoryDTO);
	}

	deactivateWorkCategoryForCustomer(workCategoryDTO:WorkCategoryDTO) : Observable<WorkCategoryDTO>{
		
		return this.api.post("deactivateWorkCategoryForCustomer", workCategoryDTO);
	}

	saveWorkCategory(workCategoryDTO:WorkCategoryDTO) : Observable<WorkCategoryDTO>{
		
		return this.api.post("saveWorkCategory", workCategoryDTO);
	}
}
