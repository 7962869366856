import { Component, ViewChild, ElementRef, TemplateRef, OnInit } from '@angular/core';
import {OfferDTO} from '../../models/offer';
import {OfferService} from '../../services/offer.service';
import {AdvertiserDTO} from '../../models/advertiser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit{

	message:string = null;
	loading:boolean = false;
	advertiserList:AdvertiserDTO[] = [];
	offerList:OfferDTO[] = [];
	offerDTO:OfferDTO = new OfferDTO();
	offerSearchDTO:OfferDTO = new OfferDTO();
	mode:string = "list";

	@ViewChild("startDate") startDate:ElementRef;
	@ViewChild("endDate") endDate:ElementRef;

	@ViewChild("saveOfferModal") saveOfferModal:TemplateRef<any>;
	@ViewChild("activateOfferModal") activateOfferModal:TemplateRef<any>;
	@ViewChild("deactivateOfferModal") deactivateOfferModal:TemplateRef<any>;
	
	modalRef: BsModalRef;

	constructor(private offerService:OfferService,
				private modalService: BsModalService) {
	}

	ngOnInit(): void {
	
		this.offerService.getAdvertiserList().subscribe((advertiserList:AdvertiserDTO[]) => {

			this.advertiserList = advertiserList;
		});
		
		this.searchOffers();
	}

	searchOffers(){
		
		this.loading = true;

		this.mode = "list";
		
		this.offerService.searchOffers(this.offerSearchDTO).subscribe((offerList:OfferDTO[]) => {

			this.loading = false;

			this.offerList = offerList;
		});
	}

	exportOffers(){

		this.offerService.exportOffers(this.offerSearchDTO);
	}

	addOffer(){

		this.mode = "edit";
		
		this.offerDTO = new OfferDTO();
	}

	editOffer(offerDTO:OfferDTO){

		this.mode = "edit";

		this.offerDTO = offerDTO;
	}

	openActiveateOfferModal(offerDTO:OfferDTO){

		this.offerDTO = offerDTO;

		this.modalRef = this.modalService.show(this.activateOfferModal);
	}

	openDeactiveateOfferModal(offerDTO:OfferDTO){

		this.offerDTO = offerDTO;

		this.modalRef = this.modalService.show(this.deactivateOfferModal);
	}

	openSaveOfferModal() {
		
		this.modalRef = this.modalService.show(this.saveOfferModal);
	}

	updatePrimaryImage(event:any){

		if(event.target.files && event.target.files[0]) {

			var reader = new FileReader();

			reader.onload = (event:any) => {

				this.offerDTO.photo = event.target.result;
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}

	updateDetailsImage(event:any){

		if(event.target.files && event.target.files[0]) {

			var reader = new FileReader();

			reader.onload = (event:any) => {

				this.offerDTO.detailsPhoto = event.target.result;
			}

			reader.readAsDataURL(event.target.files[0]);
		}
	}

	saveOffer(){
		
		this.modalRef.hide();

		this.offerDTO.startDate = this.startDate.nativeElement.value;
		this.offerDTO.endDate = this.endDate.nativeElement.value;

		this.offerService.saveOffer(this.offerDTO).subscribe((offerDTO:OfferDTO) => {

			this.message = "Offer " + offerDTO.id + " has been saved successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchOffers();
		});
	}

	activateOffer(){

		this.modalRef.hide();

		this.offerService.activateOffer(this.offerDTO).subscribe((offerDTO:OfferDTO) => {

			this.message = "Offer " + offerDTO.id + " has been activated successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchOffers();
		});
	}

	deactivateOffer(){
		
		this.modalRef.hide();
		
		this.offerService.deactivateOffer(this.offerDTO).subscribe((offerDTO:OfferDTO) => {

			this.message = "Offer " + offerDTO.id + " has been deactivated successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchOffers();
		});
	}
}
