import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import {WorkRequestBidDTO} from '../../models/work-request-bid';
import {StatusDTO} from '../../models/status';
import {WorkRequestService} from '../../services/work-request.service';
import {HuloolConstants} from '../../hulool.constants';
import { CommonComponent } from '../common.component';
declare var $:JQueryStatic;

@Component({
  selector: 'app-work-bid',
  templateUrl: './work-bid.component.html',
  styleUrls: ['./work-bid.component.css']
})
export class WorkBidComponent extends CommonComponent implements OnInit {

	loading:boolean = false;
	workRequestBidList:WorkRequestBidDTO[] = [];
	workRequestBidDTO:WorkRequestBidDTO = new WorkRequestBidDTO();
	workRequestBidSearchDTO:WorkRequestBidDTO = new WorkRequestBidDTO();
	statusList:StatusDTO[] = [];

	@ViewChild("startDate") startDate:ElementRef;
	@ViewChild("endDate") endDate:ElementRef;

	constructor(private workRequestService:WorkRequestService) {
		super();
	}

	ngOnInit(): void {

		this.workRequestService.getStatusById(HuloolConstants.SUPPLIER_BID_SUBMITTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.SUPPLIER_BID_APPROVED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.SUPPLIER_BID_REJECTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		let date:Date = new Date();

		date.setDate(date.getDate() - 7);

		let day:string = String(date.getDate());
		let month:string = String(date.getMonth() + 1);
		let year:string = String(date.getFullYear());

		this.startDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		date = new Date();
		day = String(date.getDate());
		month = String(date.getMonth() + 1);
		year = String(date.getFullYear());

		this.endDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		this.searchWorkBids();

		$(".datePicker").each(function(){

            $(this).datepicker({format: "dd-mm-yyyy", uiLibrary: "bootstrap4"});
        })
	}

	searchWorkBids(){

		this.workRequestBidSearchDTO.startDate = this.startDate.nativeElement.value;
		this.workRequestBidSearchDTO.endDate = this.endDate.nativeElement.value;
		
		this.loading = true;

		this.workRequestBidList = [];
		
		this.workRequestService.searchWorkBids(this.workRequestBidSearchDTO).subscribe((workRequestBidList:WorkRequestBidDTO[]) => {

			this.loading = false;

			this.workRequestBidList = workRequestBidList;
		});
	}

	exportWorkBids(){

		this.workRequestService.exportWorkBids(this.workRequestBidSearchDTO);
	}
}
