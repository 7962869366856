import { Injectable } from '@angular/core';
import {Api} from './api.service';
import {ParameterDTO} from '../models/parameter';
import {Observable} from 'rxjs/Observable';
import { JobSchedulerDTO } from '../models/job-scheduler';
import { JobSchedulerHistoryDTO } from '../models/job-scheduler-history';

@Injectable()
export class ParameterService {

    constructor(public api: Api) {

    }

    getJobSchedulerList() : Observable<JobSchedulerDTO[]> {

        return this.api.get("getJobSchedulerList");
    }

    runJobScheduler(url:string) : Observable<void> {

        return this.api.get(url);
    }

    getJobSchedulerHistoryList(jobSchedulerDTO:JobSchedulerDTO) : Observable<JobSchedulerHistoryDTO[]> {

        return this.api.post("getJobSchedulerHistoryList", jobSchedulerDTO);
    }

    clearJobSchedulerLogs(jobSchedulerDTO:JobSchedulerDTO) : Observable<JobSchedulerDTO> {

        return this.api.post("clearJobSchedulerLogs", jobSchedulerDTO);
    }

    searchParameters(parameterSearchDTO:ParameterDTO) : Observable<ParameterDTO[]> {

        return this.api.post("searchParameters", parameterSearchDTO);
    }

    exportParameters(parameterSearchDTO:ParameterDTO) : void {

        this.api.download("exportParameters", parameterSearchDTO, "Parameters");
    }

    updateParameter(parameterDTO:ParameterDTO) : Observable<ParameterDTO>{

        return this.api.post("updateParameter", parameterDTO);
    }
}
