export class CommunicationConstants {

    static SMS_TARGET:string = "SMS";
    static EMAIL_TARGET:string = "Email";
    static APPLICATION_TARGET:string = "Application";
    static PUSH_NOTIFICATION_TARGET:string = "Push Notification";

    static ISSUE_WORK_REQUEST_TRANSACTION:string = "Issue Work Request";
    static APPROVE_WORK_REQUEST_TRANSACTION:string = "Approve Work Request";
    static REJECT_WORK_REQUEST_TRANSACTION:string = "Reject Work Request";

    static ISSUE_BROAD_CAST_WORK_REQUEST_TRANSACTION:string = "Issue BroadCast Work Request";

    static ISSUE_WORK_REQUEST_BID_TRANSACTION:string = "Issue Work Request Bid";
    static APPROVE_WORK_REQUEST_BID_TRANSACTION:string = "Approve Work Request Bid";
    static REJECT_WORK_REQUEST_BID_TRANSACTION:string = "Reject Work Request Bid";

    static COMPLETE_ISSUE_WORK_REQUEST_TRANSACTION:string = "Complete Work Request";

    static ISSUE_WORK_REQUEST_QUATATION_TRANSACTION:string = "Issue Work Request Quatation";
    static APPROVE_WORK_REQUEST_QUATATION_TRANSACTION:string = "Approve Work Request Quatation";
    static REJECT_WORK_REQUEST_QUATATION_TRANSACTION:string = "Reject Work Request Quatation";

    static COMPLETE_PURCHASE_ORDER_TRANSACTION:string = "Complete Purchase Order";

    static CANCEL_WORK_REQUEST_BY_CUSTOMER:string = "Cancel Work Request By Customer";
	
    static CANCEL_WORK_REQUEST_WITH_CHECK_COST_REFUND_BY_HULOOL:string = "Cancel Work Request By Hulool with Check Cost Refund";
	
	static CANCEL_WORK_REQUEST_WITHOUT_CHECK_COST_REFUND_BY_HULOOL:string = "Cancel Work Request By Hulool without Check Cost Refund";
	
	static CANCEL_WORK_QUOTATION_BY_HULOOL:string = "Cancel Work Request Quotation By Hulool";
	
    static CANCEL_PURCHASE_ORDER_BY_HULOOL:string = "Cancel Purchase Order By Hulool";
    
    static SEND_VERIFICATION_CODE = "Send Verification Code";
    static RESET_USER_PASSWORD = "Reset User Password";
    static ACCEPT_SUPPLIER = "Accept Supplier";
    static REJECT_SUPPLIER = "Reject Supplier";
    static SUSPEND_SUPPLIER = "Suspend Supplier";

    static ACCEPT_SUPPLIER_SPECIALIZATION = "Accept Supplier Specialization";
    static REJECT_SUPPLIER_SPECIALIZATION = "Reject Supplier Specialization";

    static ACCEPT_SUPPLIER_SPECIALIZATION_PRICE = "Accept Supplier Price List";
    static REJECT_SUPPLIER_SPECIALIZATION_PRICE = "Reject Supplier Price List";
    static DELETE_SUPPLIER_SPECIALIZATION_PRICE_BY_HUlool = "Delete Supplier Price List by Hulool";
    
    static TARGETS = [CommunicationConstants.SMS_TARGET, CommunicationConstants.EMAIL_TARGET, 
                        CommunicationConstants.APPLICATION_TARGET, CommunicationConstants.PUSH_NOTIFICATION_TARGET]

    static TRANSACTIONS = [ 
                            CommunicationConstants.SEND_VERIFICATION_CODE,
                            CommunicationConstants.RESET_USER_PASSWORD,
                            CommunicationConstants.ACCEPT_SUPPLIER,
                            CommunicationConstants.REJECT_SUPPLIER,
                            CommunicationConstants.SUSPEND_SUPPLIER,
                            CommunicationConstants.ACCEPT_SUPPLIER_SPECIALIZATION,
                            CommunicationConstants.REJECT_SUPPLIER_SPECIALIZATION,

                            CommunicationConstants.ACCEPT_SUPPLIER_SPECIALIZATION_PRICE,
                            CommunicationConstants.REJECT_SUPPLIER_SPECIALIZATION_PRICE,
                            CommunicationConstants.DELETE_SUPPLIER_SPECIALIZATION_PRICE_BY_HUlool,
                            

                            CommunicationConstants.ISSUE_WORK_REQUEST_TRANSACTION, 
                            CommunicationConstants.APPROVE_WORK_REQUEST_TRANSACTION, 
                            CommunicationConstants.REJECT_WORK_REQUEST_TRANSACTION,
                            CommunicationConstants.COMPLETE_ISSUE_WORK_REQUEST_TRANSACTION,
                            
                            CommunicationConstants.ISSUE_BROAD_CAST_WORK_REQUEST_TRANSACTION,
                            CommunicationConstants.ISSUE_WORK_REQUEST_BID_TRANSACTION, 
                            CommunicationConstants.APPROVE_WORK_REQUEST_BID_TRANSACTION, 
                            CommunicationConstants.REJECT_WORK_REQUEST_BID_TRANSACTION, 
                            
                            CommunicationConstants.ISSUE_WORK_REQUEST_QUATATION_TRANSACTION,  
                            CommunicationConstants.APPROVE_WORK_REQUEST_QUATATION_TRANSACTION, 
                            CommunicationConstants.REJECT_WORK_REQUEST_QUATATION_TRANSACTION,
                        
                            CommunicationConstants.COMPLETE_PURCHASE_ORDER_TRANSACTION,

                            CommunicationConstants.CANCEL_WORK_REQUEST_BY_CUSTOMER,

                            CommunicationConstants.CANCEL_WORK_REQUEST_WITH_CHECK_COST_REFUND_BY_HULOOL,
                            CommunicationConstants.CANCEL_WORK_REQUEST_WITHOUT_CHECK_COST_REFUND_BY_HULOOL,
                            CommunicationConstants.CANCEL_WORK_QUOTATION_BY_HULOOL,
                            CommunicationConstants.CANCEL_PURCHASE_ORDER_BY_HULOOL,

                        ];
}