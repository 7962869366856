import {UserDTO} from './user';
import {NotificationDTO} from './notification';
import {LoginDTO} from './login';
import {CommonDTO} from './common';

export class BaseDeviceDTO extends CommonDTO {

	id:number;
	userDTO:UserDTO = new UserDTO();
	token:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	notificationList:Array<NotificationDTO>;
	loginList:Array<LoginDTO>;
}