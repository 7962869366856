import {NotificationDTO} from './notification';
import {CommonDTO} from './common';

export class BaseCommunicationDTO extends CommonDTO {

	id:number;
	transaction:string;
	category:string;
	englishTitle:string;
	arabicTitle:string;
	englishContent:string;
	arabicContent:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	notificationList:Array<NotificationDTO>;
}