import {CommunicationDTO} from './communication';
import {DeviceDTO} from './device';
import {StatusDTO} from './status';
import {UserDTO} from './user';
import {WorkRequestDTO} from './work-request';
import {CommonDTO} from './common';

export class BaseNotificationDTO extends CommonDTO {

	id:number;
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	userDTO:UserDTO = new UserDTO();
	communicationDTO:CommunicationDTO = new CommunicationDTO();
	statusDTO:StatusDTO = new StatusDTO();
	deviceDTO:DeviceDTO = new DeviceDTO();
	mobileNo:string;
	email:string;
	title:string;
	content:string;
	time:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}