import {OnlinePaymentDTO} from './online-payment';
import {StatusDTO} from './status';
import {WorkCategoryDTO} from './work-category';
import {CityDTO} from './city';
import {CustomerDTO} from './customer';
import {AddressDTO} from './address';
import {SupplierDTO} from './supplier';
import {WorkSubCategoryDTO} from './work-sub-category';
import {PaymentItemDTO} from './payment-item';
import {NotificationDTO} from './notification';
import {CreditHistoryDTO} from './credit-history';
import {PurchaseOrderDTO} from './purchase-order';
import {WorkQuatationDTO} from './work-quatation';
import {WorkRequestBidDTO} from './work-request-bid';
import {WorkOrderDTO} from './work-order';
import {WorkRequestHistoryDTO} from './work-request-history';
import {CommonDTO} from './common';

export class BaseWorkRequestDTO extends CommonDTO {

	id:number;
	supplierDTO:SupplierDTO = new SupplierDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	workCategoryDTO:WorkCategoryDTO = new WorkCategoryDTO();
	workSubCategoryDTO:WorkSubCategoryDTO = new WorkSubCategoryDTO();
	addressDTO:AddressDTO = new AddressDTO();
	cityDTO:CityDTO = new CityDTO();
	statusDTO:StatusDTO = new StatusDTO();
	title:string;
	description:string;
	contactInfo:string;
	checkCost:number;
	checkCostPaymentDTO:OnlinePaymentDTO = new OnlinePaymentDTO();
	broadcast:boolean;
	useCreditCheckCost:boolean;
	issueTime:string;
	statusTime:string;
	rate:number;
	attachment:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	paymentItemList:Array<PaymentItemDTO>;
	notificationList:Array<NotificationDTO>;
	creditHistoryList:Array<CreditHistoryDTO>;
	purchaseOrderList:Array<PurchaseOrderDTO>;
	workQuatationList:Array<WorkQuatationDTO>;
	workRequestBidList:Array<WorkRequestBidDTO>;
	workOrderList:Array<WorkOrderDTO>;
	workRequestHistoryList:Array<WorkRequestHistoryDTO>;
}