import {SupplierSpecializationDTO} from './supplier-specialization';
import {StatusDTO} from './status';
import {CommonDTO} from './common';

export class BaseSupplierSpecializationPriceDTO extends CommonDTO {

	id:number;
	supplierId:number;
	specializationDTO:SupplierSpecializationDTO = new SupplierSpecializationDTO();
	statusDTO:StatusDTO = new StatusDTO();
	priceDocument:string;
	rejectionReason:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}