import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import { _throw } from 'rxjs/observable/throw';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';

@Injectable()
export class Api {

    @Output() event = new EventEmitter<HttpErrorResponse>();

    constructor(public http: HttpClient,
                public dataService:DataService) {

    }

    getString(endpoint: string) : Observable<string> {
        
        const headers = new HttpHeaders({"Content-Type" :  "application/json; charset=utf-8"});

        return this.http.get(environment.url + endpoint, {responseType:"text", headers:headers});
    }

    get<T>(endpoint: string) : Observable<T> {

        let headers = {headers: new HttpHeaders({"Content-Type" : "application/json; charset=utf-8"})};

        return this.http.get<T>(environment.url + endpoint, headers).catch((error:HttpErrorResponse)=>{

            console.log(error);

            if(error.status == 0){

                this.dataService.share("No Internet Connection .. please try again");

            } else if(error.status == 500){

                this.dataService.share("Some error occur while processing .. please check again later");

            }else{

                this.dataService.share(error.error);
            }

            return Observable.of<T>();
        });
    }

    post<T>(endpoint: string, body:any) : Observable<T> {

        let headers = {headers: new HttpHeaders({"Content-Type" : "application/json; charset=utf-8"})};

        return this.http.post<T>(environment.url + endpoint, body, headers).catch((error:HttpErrorResponse)=>{

            console.log(error);
            
            if(error.status == 0){

                this.dataService.share("No Internet Connection .. please try again");

            } else if(error.status == 500){

                this.dataService.share("Some error occur while processing .. please check again later");

            }else{

                this.dataService.share(error.error);
            }

            return Observable.of<T>();
        });
    }

    postImage64(endpoint: string, body:string) : Observable<string> {

        return this.http.post(environment.url + endpoint, body, {responseType: "text"});
    }

    download(endpoint: string, body:any, fileName:string) : void {

        this.http.post(environment.url + endpoint, body, {responseType: "blob"}).subscribe((file:any)=>{

            let blob = new Blob([file]);

            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + ".xlsx";
            link.click();

        }, (error)=>{

            console.log(error);
        });
    }

    downloadFile(endpoint: string, body:any, fileName:string ,fileType:String) : void {

        this.http.post(environment.url + endpoint, body, {responseType: "blob"}).subscribe((file:any)=>{

            let blob = new Blob([file]);

            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName + "."+fileType;
            link.click();

        }, (error)=>{

            console.log(error);
        });
    }
} 