import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {StatusDTO} from '../../models/status';
import {StatusService} from '../../services/status.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {

	message:string = null;
	loading:boolean = false;
	statusList:StatusDTO[] = [];
	statusDTO:StatusDTO = new StatusDTO();
	statusSearchDTO:StatusDTO = new StatusDTO();
	mode:string = "";

	@ViewChild("saveStatusModal") saveStatusModal:TemplateRef<any>;
	
	modalRef: BsModalRef;

	constructor(private statusService:StatusService,
				private modalService: BsModalService) {
	}

	ngOnInit(): void {

		this.searchStatuses();
	}

	searchStatuses(){

		this.loading = true;

		this.mode = "list";

		this.statusService.searchStatusList(this.statusSearchDTO).subscribe((statusList:StatusDTO[])=>{

			this.loading = false;

			this.statusList = statusList;
		})
	}

	exportStatuses(){

		this.statusService.exportStatuses(this.statusSearchDTO);
	}

	editStatus(statusDTO:StatusDTO){

		this.mode = "edit";
		
		this.statusDTO = statusDTO;
	}

	openSaveStatusModal() {
		
		this.modalRef = this.modalService.show(this.saveStatusModal);
	}

	updateStatus(){

		this.modalRef.hide();

		this.mode = "list";

		this.statusService.updateStatus(this.statusDTO).subscribe((statusDTO:StatusDTO) =>{
		
			this.message = "Status " + statusDTO.id + " has been saved successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);
		});
	}

	toHTML(value:string) : string{

		if(value != null){

			return value.replace(/\n/g, "<br>");
		}

		return null;
	}
}
