export class HuloolConstants {

	static USER_VERIFICATION_REQUIRED:number = 1;
	static USER_CHANGE_PASSWORD_REQUIRED:number = 36;
    static USER_ACTIVE:number = 2;
	static USER_LOCKED:number = 3;
	
	static SUPPLIER_REGISTERED:number = 4;
	static SUPPLIER_APPROVED:number = 5;
	static SUPPLIER_REJECTED:number = 6;
	static SUPPLIER_SUSPENDED:number = 7;
	
	static SUPPLIER_BID_SUBMITTED:number = 8;
	static SUPPLIER_BID_IGNORED:number = 9;
	static SUPPLIER_BID_APPROVED:number = 10;
	static SUPPLIER_BID_REJECTED:number = 11;
	
	static SPECIALIZATION_SUBMITTED:number = 12;
	static SPECIALIZATION_APPROVED:number = 13;
	static SPECIALIZATION_REJECTED:number = 14;
	static SPECIALIZATION_DELETED:number = 35;

	static NOTIFICATION_SENT_SUCCESS:number = 15;
	static NOTIFICATION_SENT_FAILED:number = 16;
	static NOTIFICATION_SEEN:number = 17;

	static PAYMENT_TO_HULOOL:number = 18;
	static PAYMENT_TO_SUPPLIER:number = 19;
	static REFUNDED_TO_CUSTOMER:number = 40;

	static AWAIT_SUPPLIER_RESPONSE:number = 20;
	static REJECTED_BY_SUPPLIER:number = 21;
	static AWAIT_SUPPLIERS_BID:number = 22;
	static AWAIT_CUSTOMER_RESPONSE:number = 23;
	static CANCELED_BY_CUSTOMER:number = 24;
	static CANCELED_BY_HULOOL_SCHEDULER:number = 25;
	static PENDING_CHECK:number = 26;
	static CHECK_COMPLETED:number = 27;
	static QUATATION_SUBMITTED:number = 28;
	static QUATATION_APPROVED:number = 29;
	static QUATATION_REJECTED:number = 30;
	static PURCHASE_ORDER_COMPLETED:number = 31;
	static QUATATION_EXPIRED:number = 32;
	static PURCHASE_ORDER_CANCELED_BY_HULOOL:number = 33;
	static CANCELED_BY_HULOOL:number = 34;
	static QUATATION_CANCELED_BY_HULOOL:number = 37;
	static CHECK_COST_PAYMENT_CANCELED:number = 38;
	static PENDING_PAYMENT:number = 39;
	static REFUND_TO_CUSTOMER  = 40;
	static SPECIALIZATION_PRICE_SUBMITTED  = 41;
	static SPECIALIZATION_PRICE_APPROVED  = 42;
	static SPECIALIZATION_PRICE_REJECTED =43;
	static SPECIALIZATION_PRICE_REJECTED_BY_SUPPLIER =44;
	static SPECIALIZATION_PRICE_REJECTED_BY_HULOOL =45;

	static ADMIN_ROLE_ID = 1; 
	static SUPPLIER_ROLE_ID = 2; 
	static CUSTOMER_ROLE_ID = 3; 
}