import { Injectable } from '@angular/core';
import {Api} from './api.service';
import {WorkCategoryDTO} from '../models/work-category';
import {WorkSubCategoryDTO} from '../models/work-sub-category';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class WorkSubCategoryService {

	constructor(public api: Api) {

	}

	searchWorkSubCategoryList(workSubCategoryDTO:WorkSubCategoryDTO) : Observable<WorkSubCategoryDTO[]> {

		return this.api.post("searchWorkSubCategories", workSubCategoryDTO);
	}

	exportWorkSubCategories(workSubCategoryDTO:WorkSubCategoryDTO){

		return this.api.download("exportWorkSubCategories", workSubCategoryDTO, "Work Sub Categories");
	}

	activateWorkSubCategory(workSubCategoryDTO:WorkSubCategoryDTO) : Observable<WorkSubCategoryDTO>{

		return this.api.post("activateWorkSubCategory", workSubCategoryDTO);
	}

	deactivateWorkSubCategory(workSubCategoryDTO:WorkSubCategoryDTO) : Observable<WorkSubCategoryDTO>{
		
		return this.api.post("deactivateWorkSubCategory", workSubCategoryDTO);
	}

	saveWorkSubCategory(workSubCategoryDTO:WorkSubCategoryDTO) : Observable<WorkSubCategoryDTO>{
		
		return this.api.post("saveWorkSubCategory", workSubCategoryDTO);
	}
}
