import {CustomerDTO} from './customer';
import {WorkRequestDTO} from './work-request';
import {SupplierDTO} from './supplier';
import {PurchaseOrderDTO} from './purchase-order';
import {WorkQuatationDTO} from './work-quatation';
import {CommonDTO} from './common';

export class BaseWorkOrderDTO extends CommonDTO {

	id:number;
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	supplierDTO:SupplierDTO = new SupplierDTO();
	agreementTime:string;
	checkTime:string;
	checkTitle:string;
	checkSummary:string;
	kpi:number;
	attachment:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	purchaseOrderList:Array<PurchaseOrderDTO>;
	workQuatationList:Array<WorkQuatationDTO>;
}