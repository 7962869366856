import {CityDTO} from './city';
import {SupplierSpecializationGovernorateDTO} from './supplier-specialization-governorate';
import {CommonDTO} from './common';

export class BaseGovernorateDTO extends CommonDTO {

	id:number;
	englishName:string;
	arabicName:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	cityList:Array<CityDTO>;
	supplierSpecializationGovernorateList:Array<SupplierSpecializationGovernorateDTO>;
}