import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

	url:string = environment.url + "toConsultPro";
	content:string = "";

	constructor(public domSanitizer: DomSanitizer, public userService:UserService) {
		
		this.url = domSanitizer.bypassSecurityTrustResourceUrl(this.url) as string;

		this.userService.toConsultPro().subscribe((response:string)=>{

			this.content = domSanitizer.bypassSecurityTrustHtml(response) as string;
		});
	}
}
