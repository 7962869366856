import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import {WorkSubCategoryService} from '../../services/work-sub-category.service';
import {WorkCategoryService} from '../../services/work-category.service';
import {WorkCategoryDTO} from '../../models/work-category';
import {WorkSubCategoryDTO} from '../../models/work-sub-category';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-work-sub-category',
  templateUrl: './work-sub-category.component.html',
  styleUrls: ['./work-sub-category.component.css']
})
export class WorkSubCategoryComponent implements OnInit {
  
  message:string = null;
  loading:boolean = false;
  workCategoryList:WorkCategoryDTO[] = [];
  workSubCategoryList:WorkSubCategoryDTO[] = [];
  workSubCategoryDTO:WorkSubCategoryDTO = new WorkSubCategoryDTO();
  workSubCategorySearchDTO:WorkSubCategoryDTO = new WorkSubCategoryDTO();
  mode:string = "";

  @ViewChild("saveWorkSubCategoryModal") saveWorkSubCategoryModal:TemplateRef<any>;
  @ViewChild("activateWorkSubCategoryModal") activateWorkSubCategoryModal:TemplateRef<any>;
  @ViewChild("deactivateWorkSubCategoryModal") deactivateWorkSubCategoryModal:TemplateRef<any>;
  
  modalRef: BsModalRef;

    constructor(private workSubCategoryService:WorkSubCategoryService, 
                private workCategoryService:WorkCategoryService,
                private modalService: BsModalService) {
    }

    ngOnInit(): void {

        this.searchWorkSubCategoryList();

        let workCategoryDTO:WorkCategoryDTO = new WorkCategoryDTO();
        workCategoryDTO.active = true;
        
        this.workCategoryService.searchWorkCategoryList(workCategoryDTO).subscribe((workCategoryList:WorkCategoryDTO[]) => {

            this.workCategoryList = workCategoryList;
        });
    }

    searchWorkSubCategoryList(){

        this.loading = true;

        this.mode = "list";
        
        this.workSubCategoryService.searchWorkSubCategoryList(this.workSubCategorySearchDTO).subscribe((workSubCategoryList:WorkSubCategoryDTO[])=> {

            this.loading = false;

            this.workSubCategoryList = workSubCategoryList;
        });
    }

    exportWorkSubCategories(){

        this.workSubCategoryService.exportWorkSubCategories(this.workSubCategorySearchDTO);
    }

    addWorkSubCategory(){

        this.mode = "edit";

        this.workSubCategoryDTO = new WorkSubCategoryDTO();
    }

    editWorkSubCategory(workSubCategoryDTO:WorkSubCategoryDTO){

        this.mode = "edit";

        this.workSubCategoryDTO = workSubCategoryDTO;
    }

    openActiveateWorkSubCategoryModal(workSubCategoryDTO:WorkSubCategoryDTO){

    this.workSubCategoryDTO = workSubCategoryDTO;
    
        this.modalRef = this.modalService.show(this.activateWorkSubCategoryModal);
    }
    
    openDeactiveateWorkSubCategoryModal(workSubCategoryDTO:WorkSubCategoryDTO){
    
        this.workSubCategoryDTO = workSubCategoryDTO;
    
        this.modalRef = this.modalService.show(this.deactivateWorkSubCategoryModal);
    }
    
    openSaveWorkSubCategoryModal() {
    
        this.modalRef = this.modalService.show(this.saveWorkSubCategoryModal);
    }

    activateWorkSubCategory(){

        this.modalRef.hide();

        this.workSubCategoryService.activateWorkSubCategory(this.workSubCategoryDTO).subscribe((workSubCategoryDTO:WorkSubCategoryDTO)=>{

            this.message = "Work Sub Category " + workSubCategoryDTO.id + " has been activated successfully";

            setTimeout(()=>{

                this.message = null;

            }, 3000);

            this.searchWorkSubCategoryList();
        });
    }

    deactivateWorkSubCategory(){

        this.modalRef.hide();

        this.workSubCategoryService.deactivateWorkSubCategory(this.workSubCategoryDTO).subscribe((workSubCategoryDTO:WorkSubCategoryDTO)=>{

            this.message = "Work Sub Category " + workSubCategoryDTO.id + " has been deactivated successfully";

            setTimeout(()=>{

                this.message = null;

            }, 3000);

            this.searchWorkSubCategoryList();
        });
    }

    saveWorkSubCategory(){

        this.modalRef.hide();

        this.workSubCategoryService.saveWorkSubCategory(this.workSubCategoryDTO).subscribe((workSubCategoryDTO:WorkSubCategoryDTO)=>{

            this.message = "Work Sub Category " + workSubCategoryDTO.id + " has been saved successfully";

            setTimeout(()=>{

                this.message = null;

            }, 3000);

            this.searchWorkSubCategoryList();
        });
    }
}
