import { SharedService } from "../services/shared-service";
declare var $: any;
import { Component, HostListener } from "@angular/core";

@Component({
    template:""
})
export class CommonComponent{
	
    mode:string = null;
    loading:boolean = false;

    success(message:string){

		SharedService.publishSuccess(message);
	}

	error(error:string){

		SharedService.publishError(error);
	}

	displayCustomerDetailsPopUp(customerId:number){

		SharedService.publishDisplayCustomerDetails(customerId);
	}

	displaySupplierDetailsPopUp(supplierId:number){

		SharedService.publishDisplaySupplierDetails(supplierId);
	}

	displayPDFPopUp(imageFile:string){

		SharedService.publishPDFViwer(imageFile);
	}
    confirmAction(confirmTitle:string, confirmContent:string, functionName:Function){
		
		SharedService.publishConfirm({confirmTitle:confirmTitle, confirmContent:confirmContent, functionName:functionName});
	}

    constructor(){

    }
    
    ngOnInit(): void {
        
        let elements:NodeListOf<Element> = document.querySelectorAll("input, textarea, select");

		for(let i=elements.length-1; i>=0; i--){

			this.validateElement(elements.item(i));
		}
    }

    @HostListener('input', ['$event']) handleKeyDown(event) {

		this.validateElement(event.target);
	}

	@HostListener('document:keypress', ['$event']) handlekeypress(event) {
		
		if(event.target.type == "tel") {
      	
			var key = event.keyCode || event.which || event.charCode;

      		return key <= 31 || (key >= 48 && key <= 57);
    	}
	}

	validField(element){
	
		$(element).removeClass("is-invalid");
		
		$(element).addClass("is-valid");
		
		$(element).closest(".form-group").find(".invalid-feedback").html("");
		
		return null;
	}
	
	invalidField(element, message){
		
		$(element).removeClass("is-valid");
		
		$(element).addClass("is-invalid");
		
		if($(element).closest(".form-group").find(".invalid-feedback").length == 0){
			
			$(element).closest(".form-group").append("<span class='invalid-feedback d-block'></span>");
		
		}else{
			
			$(element).closest(".form-group").find(".invalid-feedback").html(message);
		}
		
		return message;
	}

	validateElement(element){
	
		if($(element).val() == "" || $(element).val() == "0: undefined"){
			
			if($(element).attr("required") != null){
				
				return this.invalidField(element, $(element).attr("placeholder") + " is required");
			}
			
			if($(element).hasClass("required")){
				
				return this.invalidField(element, $(element).attr("placeholder") + " is required");
			}
			
		}else{
			
			if($(element).attr("required") != null){
				
				if($(element).attr("type") == "checkbox" || $(element).attr("type") == "radio"){
					
					var name = $(element).attr("name");
					
					var checked = false;
					
					$("input[name='" + name + "']").each(function(){
						
						if($(this).prop("checked")){
							
							checked = true;
						}
					});
					
					if(!checked){
						
						return this.invalidField(element, $(element).attr("placeholder") + " is required");
					}
				}
			}
			
			if($(element).hasClass("cpr")){
			
				if($(element).val().toString().length != 9){
					
					return this.invalidField(element, $(element).attr("placeholder") + " should be 9 digits");
				}
				
				if(!this.checkCpr($(element).val())){
					
					return this.invalidField(element, $(element).attr("placeholder") + " is not valid");
				}
			}
			
			if($(element).hasClass("name")){
				
				if($(element).val().toString().trim().split(" ").length <= 2){
					
					return this.invalidField(element, $(element).attr("placeholder") + " should contains three tokens");
				}
			}
			
			if($(element).hasClass("mobile")){
				
				if(!$(element).val().toString().startsWith("3") && !$(element).val().toString().startsWith("6")){
					
					return this.invalidField(element, $(element).attr("placeholder") + " should start with 6 or 3");
				}
				
				if($(element).val().toString().length != 8){
					
					return this.invalidField(element, $(element).attr("placeholder") + " should be 8 digits");
				}
			}
			
			if($(element).attr("type") == "email" && !this.isValidEmail($(element).val())){
				
				return this.invalidField(element, $(element).attr("placeholder") + " is not valid");
			}
			
			if($(element).hasClass("email") && !this.isValidEmail($(element).val())){
				
				return this.invalidField(element, $(element).attr("placeholder") + " is not valid");
			}
			
			if($(element).attr("min") != null){
				
				if(Number($(element).val()) < Number($(element).attr("min"))){
					
					return this.invalidField(element, "Minimum value of " + $(element).attr("placeholder") + " is " + $(element).attr("min"));
				}
			}
			
			if($(element).attr("max") != null){
				
				if(Number($(element).val()) > Number($(element).attr("max"))){
					
					return this.invalidField(element, "Minimum value of " + $(element).attr("placeholder") + " is " + $(element).attr("max"));
				}
			}
		}
		
		return this.validField(element);
	}

	checkCpr(cpr) {

		if(cpr != null && cpr.length == 9){
			
			var chkdigit = cpr.charAt(8);
		
			var x = cpr.charAt(7)*2 + cpr.charAt(6)*3 + cpr.charAt(5)*4 + cpr.charAt(4)*5 + cpr.charAt(3)*6 + cpr.charAt(2)*7 + cpr.charAt(1)*8 + cpr.charAt(0)*9;
	
			var z = x%11;
			
			var calcdigit;
	
			if(z == 0 || z == 1) {
	
				calcdigit = 0;
	
			} else {
	
				calcdigit  = 11 - z;
			}
			
			return chkdigit == calcdigit;
			
		}else{
			
			return false;
		}
	}
	
	isValidEmail(email) {
		
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		
		return re.test(email);
	}

	isValidForm(){
	
		var message = null;
		
		let elements:NodeListOf<Element> = document.querySelectorAll("input, textarea, select");

		for(let i=elements.length-1; i>=0; i--){

			var elementMsg = this.validateElement(elements.item(i));

			if(elementMsg != null && elementMsg != ""){
				
				message = elementMsg;
			}
		}

		return message;
	}

	openSupplierDetailsPopUp(supplierId:number){
		this.displaySupplierDetailsPopUp(supplierId);
	}

	openCustomerDetailsPopUp(customerId:number){
		this.displayCustomerDetailsPopUp(customerId);
	}

	openPDFViewerPopUp(imageFile:string){
		this.displayPDFPopUp(imageFile);
	}
}