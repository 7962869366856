import { Component, ElementRef, ViewChild, OnInit, TemplateRef } from '@angular/core';
import {WorkRequestDTO} from '../../models/work-request';
import {WorkRequestHistoryDTO} from '../../models/work-request-history';
import {StatusDTO} from '../../models/status';
import {WorkRequestService} from '../../services/work-request.service';
import {HuloolConstants} from '../../hulool.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WorkOrderDTO } from '../../models/work-order';
import { WorkQuatationDTO } from '../../models/work-quatation';
import { PurchaseOrderDTO } from '../../models/purchase-order';
import { CommonComponent } from '../common.component';
declare var $:JQueryStatic;

@Component({
  selector: 'app-work-request',
  templateUrl: './work-request.component.html',
  styleUrls: ['./work-request.component.css']
})
export class WorkRequestComponent extends CommonComponent implements OnInit {

	loading:boolean = false;
	mode:string = "list";
	workRequestList:WorkRequestDTO[] = [];
	workRequestHistoryList:WorkRequestHistoryDTO[] = [];
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	workOrderDTO:WorkOrderDTO = new WorkOrderDTO();
	workQuatationDTO:WorkQuatationDTO = new WorkQuatationDTO();
	purchaseOrderDTO:PurchaseOrderDTO = new PurchaseOrderDTO();
	workRequestSearchDTO:WorkRequestDTO = new WorkRequestDTO();
	statusList:StatusDTO[] = [];

	canceledStatusList:number[] = [HuloolConstants.AWAIT_SUPPLIER_RESPONSE, 
									HuloolConstants.PENDING_CHECK,
									HuloolConstants.CHECK_COMPLETED,
									HuloolConstants.QUATATION_SUBMITTED,
									HuloolConstants.AWAIT_SUPPLIERS_BID,
									HuloolConstants.AWAIT_CUSTOMER_RESPONSE,
									//HuloolConstants.QUATATION_APPROVED,
									//HuloolConstants.PURCHASE_ORDER_COMPLETED
									];


	@ViewChild("startDate") startDate:ElementRef;
	@ViewChild("endDate") endDate:ElementRef;

	@ViewChild("cancelWorkRequestModal") cancelWorkRequestModal:TemplateRef<any>;

	modalRef: BsModalRef;

	constructor(private workRequestService:WorkRequestService,
				private modalService: BsModalService) {
					super();

	}

	ngOnInit(): void {

		this.workRequestService.getStatusById(HuloolConstants.PENDING_PAYMENT).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.AWAIT_SUPPLIER_RESPONSE).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.REJECTED_BY_SUPPLIER).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.AWAIT_SUPPLIERS_BID).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.AWAIT_CUSTOMER_RESPONSE).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});
		
		this.workRequestService.getStatusById(HuloolConstants.CANCELED_BY_CUSTOMER).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.CHECK_COST_PAYMENT_CANCELED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.CANCELED_BY_HULOOL_SCHEDULER).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.CANCELED_BY_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});
		
		this.workRequestService.getStatusById(HuloolConstants.PENDING_CHECK).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.CHECK_COMPLETED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_SUBMITTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_APPROVED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_REJECTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_EXPIRED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_CANCELED_BY_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.PURCHASE_ORDER_COMPLETED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.PURCHASE_ORDER_CANCELED_BY_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});
		
		let date:Date = new Date();

		date.setDate(date.getDate() - 7);

		let day:string = String(date.getDate());
		let month:string = String(date.getMonth() + 1);
		let year:string = String(date.getFullYear());

		this.startDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		date = new Date();
		day = String(date.getDate());
		month = String(date.getMonth() + 1);
		year = String(date.getFullYear());

		this.endDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		this.searchWorkRequests();

		$(".datePicker").each(function(){

            $(this).datepicker({format: "dd-mm-yyyy", uiLibrary: "bootstrap4"});
        })
	}

	searchWorkRequests(){

		this.workRequestSearchDTO.startDate = this.startDate.nativeElement.value;
		this.workRequestSearchDTO.endDate = this.endDate.nativeElement.value;

		this.loading = true;

		this.mode = "list";
	
		this.workRequestService.searchWorkRequests(this.workRequestSearchDTO).subscribe((workRequestList:WorkRequestDTO[]) => {

			this.loading = false;

			this.workRequestList = workRequestList;
		});
	}

	exportWorkRequests(){

		this.workRequestService.exportWorkRequests(this.workRequestSearchDTO);
	}

	viewWorkRequestHistory(workRequestDTO:WorkRequestDTO){

		this.mode = "history";

		this.workRequestService.fetchWorkRequestHistory(workRequestDTO).subscribe((workRequestHistoryList:WorkRequestHistoryDTO[]) => {

			this.workRequestHistoryList = workRequestHistoryList;
		});
	}

	viewWorkRequestDetails(workRequestDTO:WorkRequestDTO){

		this.mode = "view";

		this.workRequestDTO = workRequestDTO;

		this.workRequestService.getWorkOrderByWorkRequest(this.workRequestDTO).subscribe((workOrderDTO:WorkOrderDTO)=>{

			if(workOrderDTO != null){

				this.workOrderDTO = workOrderDTO;
			}
		})

		this.workRequestService.getWorkQuatationByWorkRequest(this.workRequestDTO).subscribe((workQuatationDTO:WorkQuatationDTO)=>{

			if(workQuatationDTO != null){

				this.workQuatationDTO = workQuatationDTO;
			}
		})

		this.workRequestService.getPurchaseOrderByWorkRequest(this.workRequestDTO).subscribe((purchaseOrderDTO:PurchaseOrderDTO)=>{

			if(purchaseOrderDTO != null){

				this.purchaseOrderDTO = purchaseOrderDTO;
			}
		})
	}

	openCancelWorkRequestModal() {
		
		this.modalRef = this.modalService.show(this.cancelWorkRequestModal);
	}

	cancelWorkRequestByHulool(){

		this.modalRef.hide();
		
		this.workRequestService.cancelWorkRequestByHulool(this.workRequestDTO).subscribe((workRequestDTO:WorkRequestDTO) => {

			this.workRequestDTO = workRequestDTO;
		});
	}

	formatMinutes(time:number){
				
		let formattedTime:string = "";
		
		let hours:string = String(Math.floor(time/60));
		
		let minutes:string = String(time%60);
		
		let formattedHours:string = "00".substr(hours.length) + hours;
		
		let formattedMinutes:string = "00".substr(minutes.length) + minutes;
		
		formattedTime = formattedHours + ":" + formattedMinutes;
		
		return formattedTime;
	}


}
