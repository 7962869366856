import {SupplierDTO} from './supplier';
import {WorkCategoryDTO} from './work-category';
import {StatusDTO} from './status';
import {SupplierSpecializationCoverageDTO} from './supplier-specialization-coverage';
import {SupplierSpecializationPriceDTO} from './supplier-specialization-price';
import {SupplierSpecializationGovernorateDTO} from './supplier-specialization-governorate';
import {CommonDTO} from './common';

export class BaseSupplierSpecializationDTO extends CommonDTO {

	id:number;
	supplierDTO:SupplierDTO = new SupplierDTO();
	workCategoryDTO:WorkCategoryDTO = new WorkCategoryDTO();
	statusDTO:StatusDTO = new StatusDTO();
	rejectionReason:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	supplierSpecializationCoverageList:Array<SupplierSpecializationCoverageDTO>;
	supplierSpecializationPriceList:Array<SupplierSpecializationPriceDTO>;
	supplierSpecializationGovernorateList:Array<SupplierSpecializationGovernorateDTO>;
}