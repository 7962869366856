import {StatusDTO} from './status';
import {CustomerDTO} from './customer';
import {WorkRequestDTO} from './work-request';
import {PaymentDTO} from './payment';
import {SupplierDTO} from './supplier';
import {OnlinePaymentDTO} from './online-payment';
import {CommonDTO} from './common';

export class BasePaymentItemDTO extends CommonDTO {

	id:number;
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	supplierDTO:SupplierDTO = new SupplierDTO();
	paymentDTO:PaymentDTO = new PaymentDTO();
	onlinePaymentDTO:OnlinePaymentDTO = new OnlinePaymentDTO();
	amount:number;
	payGccPercent:number;
	payGccExtraCharge:number;
	payGccBenefit:number;
	huloolBenefit:number;
	amountToSupplier:number;
	finalAmountToSupplier:number;
	comment:string;
	transaction:string;
	statusDTO:StatusDTO = new StatusDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}