import { Injectable } from '@angular/core';
import {Api} from './api.service';
import {StatusDTO} from '../models/status';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class StatusService {

	constructor(public api: Api) {

	}

	searchStatusList(statusSearchDTO:StatusDTO) : Observable<StatusDTO[]> {
	
		return this.api.post("searchStatusList", statusSearchDTO);
	}

	exportStatuses(statusSearchDTO:StatusDTO) : void {
	
		this.api.download("exportStatuses", statusSearchDTO, "Statuses");
	}

	updateStatus(statusDTO:StatusDTO){

		return this.api.post("updateStatus", statusDTO);
	}
}
