import { Injectable } from '@angular/core';
import {Api} from './api.service';
import {Observable} from 'rxjs/Observable';
import {CustomerDTO} from '../models/customer';
import {CreditHistoryDTO} from '../models/credit-history';
import { AddressDTO } from '../models/address';
import { OnlinePaymentDTO } from '../models/online-payment';

@Injectable()
export class CustomerService {

	constructor(public api: Api) {

	}
	getCustomerAccount(customerDTO:CustomerDTO) : Observable<CustomerDTO>{

        return this.api.get("getCustomerAccount/" + customerDTO.id);
    }

	searchCustomers(customerSearchDTO:CustomerDTO) : Observable<CustomerDTO[]>{

		return this.api.post("searchCustomers", customerSearchDTO);
	}

	exportCustomers(customerSearchDTO:CustomerDTO) : void{

		this.api.download("exportCustomers", customerSearchDTO, "Customers");
	}

	viewCreditHistory(customerDTO:CustomerDTO) : Observable<CreditHistoryDTO[]>{

		return this.api.get("getCustomerCreditHistoryList/" + customerDTO.id);
	}

	viewAddressList(customerDTO:CustomerDTO) : Observable<AddressDTO[]>{

		return this.api.get("getCustomerAddressList/" + customerDTO.id);
	}

	addHuloolCredit(creditHistoryDTO:CreditHistoryDTO) : Observable<CreditHistoryDTO>{

		return this.api.post("addHuloolCredit", creditHistoryDTO);
	}

	searchOnlinePayments(onlinePaymentDTO:OnlinePaymentDTO) : Observable<Array<OnlinePaymentDTO>> {

		return this.api.post<Array<OnlinePaymentDTO>>("searchOnlinePayments", onlinePaymentDTO);
	}

	exportOnlinePayments(onlinePaymentDTO:OnlinePaymentDTO) : void{

		this.api.download("exportOnlinePayments", onlinePaymentDTO, "Online Payments");
	}
}
