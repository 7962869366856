import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import {CommunicationDTO} from '../../models/communication';
import {CommunicationConstants} from '../../communication.constants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {

	message:string = null;
	loading:boolean = false;
	communicationList:CommunicationDTO[] = [];
	communicationDTO:CommunicationDTO = new CommunicationDTO();
	communicationSearchDTO:CommunicationDTO = new CommunicationDTO();

	targets = CommunicationConstants.TARGETS;
	transactions = CommunicationConstants.TRANSACTIONS;
	
	@ViewChild("saveCommunicationModal") saveCommunicationModal:TemplateRef<any>;
	@ViewChild("activateCommunicationModal") activateCommunicationModal:TemplateRef<any>;
	@ViewChild("deactivateCommunicationModal") deactivateCommunicationModal:TemplateRef<any>;
	
	modalRef: BsModalRef;

	mode:string = "list";

	constructor(private communicationService:CommunicationService,
					private modalService: BsModalService) {
	}

	ngOnInit(): void {

		this.searchCommunication();
	}

	searchCommunication(){

		this.loading = true;

		this.mode = "list";

		this.communicationService.searchCommunicationList(this.communicationSearchDTO).subscribe((communicationList:CommunicationDTO[]) => {

			this.loading = false;

			this.communicationList = communicationList;
		});
	}

	exportCommunications(){

		this.communicationService.exportCommunications(this.communicationSearchDTO);
	}

	openSaveCommunicationModal() {
		
		this.modalRef = this.modalService.show(this.saveCommunicationModal);
	}

	openActiveateCommunicationModal(communicationDTO:CommunicationDTO){

		this.communicationDTO = communicationDTO;

		this.modalRef = this.modalService.show(this.activateCommunicationModal);
	}

	openDeactiveateCommunicationModal(communicationDTO:CommunicationDTO){

		this.communicationDTO = communicationDTO;

		this.modalRef = this.modalService.show(this.deactivateCommunicationModal);
	}

	editCommunication(communicationDTO:CommunicationDTO){

		this.mode = "edit";

		this.communicationDTO = communicationDTO;
	}

	activateCommunication(){

		this.modalRef.hide();

		this.communicationService.activateCommunication(this.communicationDTO).subscribe((communicationDTO:CommunicationDTO)=>{

			this.message = "Communication " + communicationDTO.id + " has been activated successfully";

			setTimeout(()=>{

				this.message = null;
	
			}, 3000);

			this.searchCommunication();
		})
	}

	deactivateCommunication(){

		this.modalRef.hide();

		this.communicationService.deactivateCommunication(this.communicationDTO).subscribe((communicationDTO:CommunicationDTO)=>{

			this.message = "Communication " + communicationDTO.id + " has been deactivated successfully";

			setTimeout(()=>{

				this.message = null;

			}, 3000);

			this.searchCommunication();
		})
	}

	saveCommunication(){

		this.modalRef.hide();

		this.communicationService.updateCommunication(this.communicationDTO).subscribe((communicationDTO:CommunicationDTO)=>{

			this.message = "Communication " + communicationDTO.id + " has been saved successfully";

			setTimeout(()=>{

				this.message = null;
	
			}, 3000);

			this.searchCommunication();
		})
	}

	toHTML(value:string) : string{

		if(value != null){

			return value.replace(/\n/g, "<br>");
		}

		return null;	}
}
