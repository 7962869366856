import {SupplierDTO} from './supplier';
import {OfferDTO} from './offer';
import {CommonDTO} from './common';

export class BaseAdvertiserDTO extends CommonDTO {

	id:number;
	englishName:string;
	arabicName:string;
	supplierDTO:SupplierDTO = new SupplierDTO();
	logo:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	offerList:Array<OfferDTO>;
}