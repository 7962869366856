import { BehaviorSubject } from "rxjs";
import { CustomerDTO } from "../models/customer";
import { SupplierDTO } from "../models/supplier";

export class SharedService{

    static confirmModelSubject:BehaviorSubject<any> = new BehaviorSubject<any>(null);

    static successMessageSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

    static errorMessageSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

    static customerDetailsSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

    static supplierDetailsSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

    static pdfViewerSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

    static publishConfirm(confirmObject:any) : void{

        this.confirmModelSubject.next(confirmObject);
    }

    static subscribeConfirm(functionName:Function) : void{

        this.confirmModelSubject.subscribe((confirmObject:any)=>{

            if(confirmObject != null){

                functionName.call(null, confirmObject.confirmTitle, confirmObject.confirmContent, confirmObject.functionName);
            }
        });
    }

    static publishSuccess(message:string) : void{

        this.successMessageSubject.next(message);
    }

    static subscribeSuccess(functionName:Function) : void{

        this.successMessageSubject.subscribe((message:string)=>{
            
            functionName.call(null, message);
        });
    }

    static publishDisplayCustomerDetails(customerId:number) : void{

        this.customerDetailsSubject.next(customerId+"");
    }
    
    static subsribeDisplayCustomerDetails(functionName:Function) : void{

        this.customerDetailsSubject.subscribe((error:string)=>{

            functionName.call(null, error);
        });
    }

    static publishDisplaySupplierDetails(supplierId:number) : void{

        this.supplierDetailsSubject.next(supplierId+"");
    }
    
    static subsribeDisplaySupplierDetails(functionName:Function) : void{

        this.supplierDetailsSubject.subscribe((error:string)=>{

            functionName.call(null, error);
        });
    }

    static subsribeDisplayPdfViewerDetails(functionName:Function) : void{

        this.pdfViewerSubject.subscribe((error:string)=>{

            functionName.call(null, error);
        });
    }

    

    static publishPDFViwer(imageFile:string) : void{

        this.pdfViewerSubject.next(imageFile);
    }
    
    static publishError(error:string) : void{

        this.errorMessageSubject.next(error);
    }

    static subscribeError(functionName:Function) : void{

        this.errorMessageSubject.subscribe((error:string)=>{

            functionName.call(null, error);
        });
    }
}