import { Component, OnInit } from '@angular/core';
import {UserDTO} from '../../models/user';
import {UserService} from '../../services/user.service';
import { Router  } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

	userDTO:UserDTO = new UserDTO();

	constructor(public userService:UserService, public router: Router) { 
	}

	login(){

		this.userService.login(this.userDTO).subscribe((userDTO:UserDTO) => {

			if(userDTO != null && userDTO.id != null && userDTO.id != 0){

				this.router.navigateByUrl("");
			}
			
		}, (error:any) => {

			console.log("error in login component " + error);
		});
	}
}
