import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {CommunicationDTO} from '../models/communication';
import {NotificationDTO} from '../models/notification';
import {Api} from './api.service';

@Injectable()
export class CommunicationService {

	constructor(public api: Api) {

	}

	searchNotifications(notificationSearchDTO:NotificationDTO) : Observable<NotificationDTO[]> {

		return this.api.post("searchNotifications", notificationSearchDTO);
	}

	exportNotifications(notificationSearchDTO:NotificationDTO) : void {

		this.api.download("exportNotifications", notificationSearchDTO, "Notifications");
	}

	searchCommunicationList(communicationSearchDTO:CommunicationDTO) : Observable<CommunicationDTO[]> {

		return this.api.post("searchCommunicationList", communicationSearchDTO);
	}

	exportCommunications(communicationSearchDTO:CommunicationDTO) : void {

		return this.api.download("exportCommunications", communicationSearchDTO, "Communications");
	}

	updateCommunication(communicationDTO:CommunicationDTO){

		return this.api.post("updateCommunication", communicationDTO);
	}

	activateCommunication(communicationDTO:CommunicationDTO){

		return this.api.post("activateCommunication", communicationDTO);
	}

	deactivateCommunication(communicationDTO:CommunicationDTO){

		return this.api.post("deactivateCommunication", communicationDTO);
	}
}
