import {GovernorateDTO} from './governorate';
import {AddressDTO} from './address';
import {SupplierSpecializationCoverageDTO} from './supplier-specialization-coverage';
import {WorkRequestDTO} from './work-request';
import {CommonDTO} from './common';

export class BaseCityDTO extends CommonDTO {

	id:number;
	englishName:string;
	arabicName:string;
	governorateDTO:GovernorateDTO = new GovernorateDTO();
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	addressList:Array<AddressDTO>;
	supplierSpecializationCoverageList:Array<SupplierSpecializationCoverageDTO>;
	workRequestList:Array<WorkRequestDTO>;
}