import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import {NotificationDTO} from '../../models/notification';
import {CommunicationConstants} from '../../communication.constants';
import {HuloolConstants} from '../../hulool.constants';
import {CommunicationService} from '../../services/communication.service';
import {WorkRequestService} from '../../services/work-request.service';
import {StatusDTO} from '../../models/status';
declare var $:JQueryStatic;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit{

    loading:boolean = false;
    notificationList:NotificationDTO[] = [];
    notificationSearchDTO:NotificationDTO = new NotificationDTO();

    targets = CommunicationConstants.TARGETS;
    transactions = CommunicationConstants.TRANSACTIONS;
    
    statusList:StatusDTO[] = [];

    @ViewChild("startDate") startDate:ElementRef;
	@ViewChild("endDate") endDate:ElementRef;
	
    constructor(private communicationService:CommunicationService,
                private workRequestService:WorkRequestService) {
    }

    ngOnInit(): void {
        
        this.workRequestService.getStatusById(HuloolConstants.NOTIFICATION_SENT_SUCCESS).subscribe((statusDTO:StatusDTO) => {
        
            this.statusList.push(statusDTO);
        });

        this.workRequestService.getStatusById(HuloolConstants.NOTIFICATION_SENT_FAILED).subscribe((statusDTO:StatusDTO) => {
        
            this.statusList.push(statusDTO);
        });

        this.workRequestService.getStatusById(HuloolConstants.NOTIFICATION_SEEN).subscribe((statusDTO:StatusDTO) => {
        
            this.statusList.push(statusDTO);
        });

        let date:Date = new Date();

        date.setDate(date.getDate() - 7);

        let day:string = String(date.getDate());
        let month:string = String(date.getMonth() + 1);
        let year:string = String(date.getFullYear());

        this.startDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

        date = new Date();
        day = String(date.getDate());
        month = String(date.getMonth() + 1);
        year = String(date.getFullYear());

        this.endDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

        this.searchNotifications();

        $(".datePicker").each(function(){

            $(this).datepicker({format: "dd-mm-yyyy", uiLibrary: "bootstrap4"});
        })
    }

    searchNotifications(){

        this.notificationSearchDTO.startDate = this.startDate.nativeElement.value;
		this.notificationSearchDTO.endDate = this.endDate.nativeElement.value;

        this.loading = true;

        this.communicationService.searchNotifications(this.notificationSearchDTO).subscribe((notificationList:NotificationDTO[]) => {

            this.loading = false;
            
            this.notificationList = notificationList;
        });
    }

    exportNotifications(){

        this.communicationService.exportNotifications(this.notificationSearchDTO);
    }
}