import { Component, ElementRef, ViewChild, OnInit, TemplateRef } from '@angular/core';
import {HuloolConstants} from '../../hulool.constants';
import {StatusDTO} from '../../models/status';
import {WorkRequestService} from '../../services/work-request.service';
import {WorkRequestHistoryDTO} from '../../models/work-request-history';
import {WorkRequestDTO} from '../../models/work-request';
import {WorkQuatationDTO} from '../../models/work-quatation';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PurchaseOrderDTO } from '../../models/purchase-order';
import { CommonComponent } from '../common.component';
declare var $:JQueryStatic;

@Component({
  selector: 'app-work-quatation',
  templateUrl: './work-quatation.component.html',
  styleUrls: ['./work-quatation.component.css']
})
export class WorkQuatationComponent extends CommonComponent implements OnInit {

	loading:boolean = false;
	mode:string = "list";
	workRequestHistoryList:WorkRequestHistoryDTO[] = [];

	workQuatationList:WorkQuatationDTO[] = [];
	workQuatationDTO:WorkQuatationDTO = new WorkQuatationDTO();
	purchaseOrderDTO:PurchaseOrderDTO = new PurchaseOrderDTO();
	workQuatationSearchDTO:WorkQuatationDTO = new WorkQuatationDTO();

	statusList:StatusDTO[] = [];

	@ViewChild("startDate") startDate:ElementRef;
	@ViewChild("endDate") endDate:ElementRef;
	
	@ViewChild("cancelWorkRequestModal") cancelWorkRequestModal:TemplateRef<any>;

	modalRef: BsModalRef;

	canceledStatusList:number[] = [HuloolConstants.AWAIT_SUPPLIER_RESPONSE, 
		HuloolConstants.PENDING_CHECK,
		HuloolConstants.CHECK_COMPLETED,
		HuloolConstants.QUATATION_SUBMITTED,
		HuloolConstants.AWAIT_SUPPLIERS_BID,
		HuloolConstants.AWAIT_CUSTOMER_RESPONSE,
		//HuloolConstants.QUATATION_APPROVED,
		//HuloolConstants.PURCHASE_ORDER_COMPLETED
		];
		
	constructor(private workRequestService:WorkRequestService, private modalService: BsModalService) {
		super();
	}

	ngOnInit() {

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_SUBMITTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_APPROVED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_REJECTED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_EXPIRED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.QUATATION_CANCELED_BY_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.PURCHASE_ORDER_COMPLETED).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		this.workRequestService.getStatusById(HuloolConstants.PURCHASE_ORDER_CANCELED_BY_HULOOL).subscribe((statusDTO:StatusDTO) => {
		
			this.statusList.push(statusDTO);
		});

		let date:Date = new Date();

		date.setDate(date.getDate() - 7);

		let day:string = String(date.getDate());
		let month:string = String(date.getMonth() + 1);
		let year:string = String(date.getFullYear());

		this.startDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		date = new Date();
		day = String(date.getDate());
		month = String(date.getMonth() + 1);
		year = String(date.getFullYear());

		this.endDate.nativeElement.value = "00".substr(day.length) + day + "-" + "00".substr(month.length) + month + "-" + year;

		this.searchWorkQuatations();

		$(".datePicker").each(function(){

            $(this).datepicker({format: "dd-mm-yyyy", uiLibrary: "bootstrap4"});
        })
	}

	searchWorkQuatations(){

		this.mode = "list";

		this.workQuatationSearchDTO.startDate = this.startDate.nativeElement.value;
		this.workQuatationSearchDTO.endDate = this.endDate.nativeElement.value;

		this.loading = true;

		this.workRequestService.searchWorkQuatations(this.workQuatationSearchDTO).subscribe((workQuatationList:WorkQuatationDTO[]) => {

			this.loading = false;

			this.workQuatationList = workQuatationList;
		});
	}

	exportWorkQuatations(){

		this.workRequestService.exportWorkQuatations(this.workQuatationSearchDTO);
	}

	viewWorkRequestDetails(workQuatationDTO:WorkQuatationDTO){

		this.mode = "view";

		this.workQuatationDTO = workQuatationDTO;

		this.workRequestService.getPurchaseOrderByWorkRequest(this.workQuatationDTO.workRequestDTO).subscribe((purchaseOrderDTO:PurchaseOrderDTO)=>{

			if(purchaseOrderDTO != null){

				this.purchaseOrderDTO = purchaseOrderDTO;
			}
		})
	}

	viewWorkRequestHistory(workRequestDTO:WorkRequestDTO){

		this.mode = "history";

		this.workRequestService.fetchWorkRequestHistory(workRequestDTO).subscribe((workRequestHistoryList:WorkRequestHistoryDTO[]) => {

			this.workRequestHistoryList = workRequestHistoryList;
		});
	}

	formatMinutes(time:number){
				
		let formattedTime:string = "";
		
		let hours:string = String(Math.floor(time/60));
		
		let minutes:string = String(time%60);
		
		let formattedHours:string = "00".substr(hours.length) + hours;
		
		let formattedMinutes:string = "00".substr(minutes.length) + minutes;
		
		formattedTime = formattedHours + ":" + formattedMinutes;
		
		return formattedTime;
	}

	openCancelWorkRequestModal() {
		
		this.modalRef = this.modalService.show(this.cancelWorkRequestModal);
	}

	cancelWorkRequestByHulool(){

		this.modalRef.hide();
		
		this.workRequestService.cancelWorkRequestByHulool(this.workQuatationDTO.workRequestDTO).subscribe((workRequestDTO:WorkRequestDTO) => {

			this.workQuatationDTO.workRequestDTO = workRequestDTO;
		});
	}
}