import {CustomerDTO} from './customer';
import {PaymentItemDTO} from './payment-item';
import {CreditHistoryDTO} from './credit-history';
import {PurchaseOrderDTO} from './purchase-order';
import {WorkRequestDTO} from './work-request';
import {CommonDTO} from './common';

export class BaseOnlinePaymentDTO extends CommonDTO {

	id:number;
	workRequestId:number;
	customerDTO:CustomerDTO = new CustomerDTO();
	paymentMethod:string;
	paymentReference:number;
	amount:number;
	transaction:string;
	success:boolean;
	error:string;
	issueTime:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	paymentItemList:Array<PaymentItemDTO>;
	creditHistoryList:Array<CreditHistoryDTO>;
	purchaseOrderList:Array<PurchaseOrderDTO>;
	workRequestList:Array<WorkRequestDTO>;
}