import { Injectable } from '@angular/core';
import {Api} from './api.service';
import {Observable} from 'rxjs/Observable';
import {OfferDTO} from '../models/offer';
import {AdvertiserDTO} from '../models/advertiser';

@Injectable()
export class OfferService {

	constructor(public api: Api) {

	}

	getAdvertiserList() : Observable<AdvertiserDTO[]>{

		return this.api.get("getAdvertiserList");
	}

	searchAdvertisers(advertiserSearchDTO:AdvertiserDTO) : Observable<AdvertiserDTO[]>{

		return this.api.post("searchAdvertisers", advertiserSearchDTO);
	}

	exportAdvertisers(advertiserSearchDTO:AdvertiserDTO) : void{

		this.api.download("exportAdvertisers", advertiserSearchDTO, "Advertisers");
	}
	
	searchOffers(offerSearchDTO:OfferDTO) : Observable<OfferDTO[]>{

		return this.api.post("searchOffers", offerSearchDTO);
	}

	exportOffers(offerSearchDTO:OfferDTO) : void{

		this.api.download("exportOffers", offerSearchDTO, "Offers");
	}

	saveAdvertiser(advertiserDTO:AdvertiserDTO){

		return this.api.post("saveAdvertiserDetails", advertiserDTO);
	}

	activateOffer(offerDTO:OfferDTO){

		return this.api.post("activateOfferWithRefresh", offerDTO);
	}

	deactivateOffer(offerDTO:OfferDTO){
		
		return this.api.post("deactivateOfferWithRefresh", offerDTO);
	}

	saveOffer(offerDTO:OfferDTO){

		return this.api.post("saveOfferDetails", offerDTO);
	}
}
