import { Injectable } from '@angular/core';
import {SupplierDTO} from '../models/supplier';
import {PaymentDTO} from '../models/payment';
import {SupplierSpecializationDTO} from '../models/supplier-specialization';
import {Api} from './api.service';
import {Observable} from 'rxjs/Observable';
import { PaymentItemDTO } from '../models/payment-item';
import { SupplierSpecializationPriceDTO } from '../models/supplier-specialization-price';


@Injectable()
export class SupplierService {

	constructor(public api: Api) {

	}

	getSupplierAccount(supplierDTO:SupplierDTO) : Observable<SupplierDTO>{

        return this.api.get("getSupplierAccount/" + supplierDTO.id);
    }

	getApprovedSuppliers(supplierDTO:SupplierDTO) : Observable<SupplierDTO[]> {

		return this.api.post("getApprovedSuppliers", supplierDTO);
	}

	searchSuppliers(supplierSearchDTO:SupplierDTO) : Observable<SupplierDTO[]> {

		return this.api.post("searchSuppliers", supplierSearchDTO);
	}

	exportSuppliers(supplierSearchDTO:SupplierDTO) : void {

		this.api.download("exportSuppliers", supplierSearchDTO, "Suppliers");
	}

	exportPriceDocument(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO) : void {
		let documentName:string = "Specilazation-price-id-"+ supplierSpecializationPriceDTO.id;
		let fileType:String = supplierSpecializationPriceDTO.priceDocument.substring(supplierSpecializationPriceDTO.priceDocument.indexOf("/")+1 ,supplierSpecializationPriceDTO.priceDocument.indexOf(";")   );	// data:image/jpeg;
		this.api.downloadFile("exportPriceDocument", supplierSpecializationPriceDTO, documentName , fileType);
	}

	exportCommercialCertificateDocument(supplierDTO:SupplierDTO) : void {
		let documentName:string = "Commercial-certificate-id-"+ supplierDTO.id;
		this.api.downloadFile("exportCommercialCertificateDocument", supplierDTO, documentName ,"pdf");
	}

	approveSupplier(supplierDTO:SupplierDTO){
		
		return this.api.post("approveSupplier", supplierDTO);
	}

	rejectSupplier(supplierDTO:SupplierDTO){
		
		return this.api.post("rejectSupplier", supplierDTO);
	}

	suspendSupplier(supplierDTO:SupplierDTO){

		return this.api.post("suspendSupplier", supplierDTO);
	}

	updateHuloolCheckCost(supplierDTO:SupplierDTO){
		
		return this.api.post("updateHuloolCheckCost", supplierDTO);
	}

	updateSupplierDetails(supplierDTO:SupplierDTO){

		return this.api.post("updateSupplierDetails", supplierDTO);
	}

	searchSpecializationList(supplierSpecializationDTO:SupplierSpecializationDTO){

		return this.api.post("searchSpecializationList", supplierSpecializationDTO);
	}

	searchSupplierSpecializationPriceList(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO){

		return this.api.post("searchSupplierSpecializationPriceList", supplierSpecializationPriceDTO);
	}

    fetchSupplierSpecializationPriceList(supplierDTO:SupplierDTO) : Observable<SupplierSpecializationPriceDTO[]> {

        return this.api.get("fetchSupplierSpecializationPriceList/" + supplierDTO.id);
    }

	fetchSupplierSpecilizationByStatus(supplierId:number) : Observable<SupplierSpecializationDTO[]>{
		return this.api.get("fetchSupplierSpecilizationByStatus/" + supplierId);

	}
	getSupplierSpecializationPriceById(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO){

		return this.api.post("getSupplierSpecializationPriceById", supplierSpecializationPriceDTO);
	}
	
	exportSpecializations(supplierSpecializationDTO:SupplierSpecializationDTO){

		return this.api.download("exportSpecializations", supplierSpecializationDTO, "Specializations");
	}

	approveSpecialization(supplierSpecializationDTO:SupplierSpecializationDTO){

		return this.api.post("approveSpecialization", supplierSpecializationDTO);
	}

	rejectSpecialization(supplierSpecializationDTO:SupplierSpecializationDTO){

		return this.api.post("rejectSpecialization", supplierSpecializationDTO);
	}

	saveSpecializationPriceDetails(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO) : Observable<any> {

        return this.api.post("saveSpecializationPriceDetails", supplierSpecializationPriceDTO);
    }

	approveSpecializationPrice(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO){

		return this.api.post("approveSpecializationPrice", supplierSpecializationPriceDTO);
	}
	
	rejectSpecializationPrice(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO){

		return this.api.post("rejectSpecializationPrice", supplierSpecializationPriceDTO);
	}

	deleteSpecializationPriceByHulool(supplierSpecializationPriceDTO:SupplierSpecializationPriceDTO){

		return this.api.post("deleteSpecializationPriceByHulool", supplierSpecializationPriceDTO);
	}
	
	getStatusListTitles(statuses:number[]){

		return this.api.post("getStatusListTitles", statuses);
	}

	getStatusById(status:number){

		return this.api.get("getStatusById/" + status);
	}

	searchPaymentItems(paymentItemDTO:PaymentItemDTO){

		return this.api.post("searchPaymentItems", paymentItemDTO);
	}

	exportPaymentItems(paymentItemDTO:PaymentItemDTO){

		return this.api.download("exportPaymentItems", paymentItemDTO, "Payment Items");
	}

	processPayment(paymentDTO:PaymentDTO){

		return this.api.post("processPayment", paymentDTO);
	}

	searchPayments(paymentDTO:PaymentDTO){

		return this.api.post("searchPayments", paymentDTO);
	}

	exportPayments(paymentDTO:PaymentDTO){

		return this.api.download("exportPayments", paymentDTO, "Payments");
	}


}
