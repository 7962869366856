import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class DataService {

	subject = new BehaviorSubject(null);

	share(error:string){

		this.subject.next(error);
	}

	subscribe(handler:any){

		return this.subject.subscribe(handler);
	}
}
