import { Component, OnInit } from '@angular/core';
import { PaymentDTO } from '../../models/payment';
import { SupplierService } from '../../services/supplier.service';
import { SupplierDTO } from '../../models/supplier';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

	loading:boolean = false;
	mode:string = "list";
	paymentSearchDTO:PaymentDTO = new PaymentDTO();
	paymentDTO:PaymentDTO = new PaymentDTO();
	paymentList:PaymentDTO[] = [];
	supplierList:SupplierDTO[] = [];

	constructor(private supplierService:SupplierService) {

	}

	ngOnInit(): void {

		this.supplierService.getApprovedSuppliers(new SupplierDTO()).subscribe((supplierList:SupplierDTO[]) => {

			this.supplierList = supplierList;
		});

		this.searchPayments();
	}

	viewPayment(paymentDTO:PaymentDTO){

		this.mode = "view";
		
		this.paymentDTO = paymentDTO;
	}

	searchPayments(){
		
		this.loading = true;

		this.supplierService.searchPayments(this.paymentSearchDTO).subscribe((paymentList:PaymentDTO[]) => {

			this.loading = false;

			this.paymentList = paymentList;
		});
	}

	exportPayments(){
		
		this.supplierService.exportPayments(this.paymentSearchDTO);
	}
}
