import {OnlinePaymentDTO} from './online-payment';
import {WorkRequestDTO} from './work-request';
import {CustomerDTO} from './customer';
import {CommonDTO} from './common';

export class BaseCreditHistoryDTO extends CommonDTO {

	id:number;
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	paymentDTO:OnlinePaymentDTO = new OnlinePaymentDTO();
	amount:number;
	balance:number;
	transaction:string;
	time:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;
}