import {WorkOrderDTO} from './work-order';
import {WorkRequestDTO} from './work-request';
import {SupplierDTO} from './supplier';
import {CustomerDTO} from './customer';
import {PurchaseOrderDTO} from './purchase-order';
import {CommonDTO} from './common';

export class BaseWorkQuatationDTO extends CommonDTO {

	id:number;
	workRequestDTO:WorkRequestDTO = new WorkRequestDTO();
	workOrderDTO:WorkOrderDTO = new WorkOrderDTO();
	customerDTO:CustomerDTO = new CustomerDTO();
	supplierDTO:SupplierDTO = new SupplierDTO();
	title:string;
	summary:string;
	cost:number;
	issueTime:string;
	validatyDate:string;
	paymentMethod:string;
	acceptKpi:number;
	useCredit:boolean;
	attachment:string;
	active:boolean;
	recCrtDate:string;
	recUpdtDate:string;
	recCrtUser:number;
	recUpdtUser:number;

	purchaseOrderList:Array<PurchaseOrderDTO>;
}